import { LightningBoltIcon } from "@heroicons/react/outline"
import React                 from "react"

import { Button } from "./Button"

function WidgetFileDownload({title, description, onClick, buttonDisabled, badgeText, badgeColor, dataUpdatedAt}) {
  // INFO - It's needed to declare the colors in order for the Tailwind CSS processor include them in the generated CSS file
  const colors = {
    green:  ["bg-green-100" , "text-green-800" ],
    blue:   ["bg-blue-100"  , "text-blue-800"  ],
    yellow: ["bg-yellow-100", "text-yellow-800"],
    orange: ["bg-orange-100", "text-orange-800"],
  }

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <div className='flex gap-4 items-center'>
          {badgeText &&
            <span className={`inline-flex items-center rounded-full ${colors[badgeColor][0]} px-3 py-0.5 text-sm font-medium ${colors[badgeColor][1]}`}>
              {badgeText}
            </span>
          }
          <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>
          <span className="inline-flex items-center rounded-md bg-gray-100 px-2.5 py-0.5 text-sm font-medium text-gray-800">
            <LightningBoltIcon className="h-4 w-4 mr-2 text-amber-400" />
            {dataUpdatedAt}
          </span>
        </div>
        <div className="mt-2 sm:flex sm:items-start sm:justify-between">
          <div className="max-w-3xl text-sm text-gray-500">
            <p>{description}</p>
          </div>
          <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex sm:flex-shrink-0 sm:items-center">
            {buttonDisabled
              ?
                <Button color="indigo" loading={true} />
              :
                <Button color="indigo" onClick={onClick} text="Download" />
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export { WidgetFileDownload }
