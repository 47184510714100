import Layout from "./Layout"
import { BrowserRouter } from "react-router-dom";
import React, { useState } from 'react';

import "@fortawesome/fontawesome-free/css/all.min.css"
import { Login } from "./pages/Login";

function App() {
  const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("userData")))

  return (
    userData
      ?
        <BrowserRouter>
          <Layout userData={userData} setUserData={setUserData} />
        </BrowserRouter>
      :
        <Login setUserData={setUserData} />    
  )
}

export default App
