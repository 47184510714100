function LoadingSpinner() {
  return (
    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  )
}

function Button({children, color, fullWidth, groupPosition, loading, onClick, text, type, disabled, noAction, appendStyle, Icon}) {
  const COLORS = {
    white:  `border-gray-300    bg-white      text-gray-700 ${!noAction ? "hover:bg-gray-50" : ""}`,
    indigo: `border-transparent bg-indigo-600 text-white    ${!noAction ? "hover:bg-indigo-700" : ""}`,
  }

  const DISABLED_COLORS = {
    white:  "border-gray-300    bg-gray-100 text-gray-500",
    indigo: `border-transparent text-white  bg-indigo-500 ${!noAction ? "hover:bg-indigo-400" : ""}`,
  }

  const disabledState = loading || disabled

  const colorClass     = disabledState ? DISABLED_COLORS[color] : COLORS[color]
  const fullWidthClass = fullWidth ? "w-full" : ""
  const loadingClass   = loading ? "transition ease-in-out duration-150 cursor-not-allowed" : ""
  const noActionClass  = noAction ? "cursor-default" : "focus:z-10 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
  const disabledClass  = disabledState ? "cursor-not-allowed" : ""

  let groupPositionClass
  switch (groupPosition) {
    case "left":
      groupPositionClass = "rounded-l-md"
      break
    case "right":
      groupPositionClass = "rounded-r-md"
      break
    case "middle":
      groupPositionClass = "border-r-0 border-l-0"
      break
    default:
      groupPositionClass = "rounded-md"
  }

  return (
    <button
      className={`relative inline-flex items-center justify-center px-4 py-2 ${fullWidthClass} ${colorClass} ${groupPositionClass} ${disabledClass} ${loadingClass} ${noActionClass} border text-sm font-medium ${appendStyle}`}
      disabled ={disabledState}
      onClick  ={onClick}
      type     ={type}
    >
      {loading
        ?
          <>
            <LoadingSpinner />
            {text || children || "Processing ..."}
          </>
        :
          children
            ?
              children
            :
              <>
                {Icon && <Icon className="h-4 w-4 mr-2" />}
                {text}
              </>
      }
    </button>
  )
}

Button.defaultProps = {
  color:     "white" ,
  fullWidth: false   ,
  loading:   false   ,
  type:      "button",
}

export { Button }
