import { Banner } from "./Banner"

function Widget({bannerProps, children, title, widgetBodyClass}) {
  return (
    <div className="bg-white shadow overflow-hidden rounded-lg">
      <div className="flex items-center py-5 px-6">
        <h3 className="flex-grow text-lg leading-6 font-medium text-gray-900">{title}</h3>
        { bannerProps && <Banner {...bannerProps} /> }
      </div>

      <div className="flex flex-col border-t border-gray-200 px-4 py-5 sm:p-0">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className={`${widgetBodyClass} shadow overflow-hidden border-b border-gray-200 sm:rounded-lg`}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Widget.defaultProps = {
  widgetBodyClass: "",
}

export { Widget }
