import { Link }          from "react-router-dom"
import sortBy            from "lodash/sortBy"
import { useQuery, gql } from "@apollo/client"
import { useState }      from "react"

import { ApiRequestError }                    from "../components/ApiRequestError"
import { Banner }                             from "../components/Banner"
import { BrandsFoodPrescriptionDataExporter } from "../components/BrandsFoodPrescriptionDataExporter"
import { Button }                             from "../components/Button"
import { CardWithMetric }                     from "../components/CardWithMetric"
import { Checkbox }                           from "../components/Checkbox"
import { Select }                             from "../components/Select"
import { WidgetTable }                        from "../components/WidgetTable"
import {
  formatDateMonthAndYear,
  generateCountriesText,
  generateRequestContextHeaders
} from "../Utils"

const QUERY = gql`
  query {
    brandsReport {
      brandsCount
      foodsCount
      prescriptionMetricsUpdatedAt

      brands {
        id
        code
        name
        logoUrl
        createdAt
        foodsCount
        foodPrescriptionsCount

        countries {
          name
          emoji
        }  
      }

      brandsPerCountry {
        country {
          emoji
        }
        count
      }  
    }
  }
`

const COLUMNS = [
  "Name", "Countries", "Foods", "Prescriptions", "Created at"
]

const sortOptions = {
  name:          1,
  prescriptions: 2,
  mostRecent:    3,
}

function sortBrands(brands, sortByCriteria) {
  if (sortByCriteria == sortOptions.name) {
    return sortBy(brands, brand => brand.name.toLocaleUpperCase())
  } else if (sortByCriteria == sortOptions.prescriptions) {
    return sortBy(brands, brand => brand.foodPrescriptionsCount).reverse()
  } else {
    return sortBy(brands, brand => brand.createdAt).reverse()
  }
}

function generateBrandUrl(brand) {
  return `/brands/${brand.id}/${brand.code.toLocaleLowerCase().replace(/_/g, "-")}`
}

const SELECT_SORT_OPTIONS = [
  { value: sortOptions.name         , name: "Sort by name"          },
  { value: sortOptions.prescriptions, name: "Sort by prescriptions" },
  { value: sortOptions.mostRecent   , name: "Sort by most recent"   },
]

function WidgetTitle({exportEnabled, toggleExportEnabled, selectAllBrandsToExport, setDownloadEnabled, sortByCriteria, setSortByCriteria}) {
  return (
    <div className="px-4 py-5 sm:px-6 flex items-center justify-center">
      <h3 className="text-lg leading-6 font-medium text-gray-900 flex-grow">Brands</h3>
      <div className="flex">
        <span>
          <Select
            onChange={event => setSortByCriteria(+event.target.value)}
            options ={SELECT_SORT_OPTIONS}
            value   ={sortByCriteria}
          />
        </span>

        <span className="ml-4 relative z-0 inline-flex shadow-sm rounded-md">
          {exportEnabled
            ?
              <>
                <Button groupPosition="left"  onClick={selectAllBrandsToExport}        text="Select all" />
                <Button groupPosition="right" onClick={() => setDownloadEnabled(true)} text="Download"   />
              </>
            :
              <Button onClick={toggleExportEnabled} text="Export metrics" />
          }
        </span>
      </div>
    </div>
  )
}

function Brands({userData}) {
  const { loading, error, data } = useQuery(QUERY, generateRequestContextHeaders(userData))

  const [exportEnabled, setExportEnabled]     = useState(false)
  const [brandsToExport, setBrandsToExport]   = useState({})
  const [downloadEnabled, setDownloadEnabled] = useState(false)
  const [sortByCriteria, setSortByCriteria]   = useState(sortOptions.name)

  const toggleExportEnabled = () => setExportEnabled(!exportEnabled)
  const toggleBrandToExport = (brand) => {
    if (brandsToExport[brand.id]) {
      setBrandsToExport({...brandsToExport, [brand.id]: false })
    } else {
      setBrandsToExport({...brandsToExport, [brand.id]: true })
    }
  }
  const selectAllBrandsToExport = () => {
    const brands = {}

    data.brandsReport.brands.forEach(brand => brands[brand.id] = true)
    setBrandsToExport(brands)
  }

  if (loading) return <p>Loading...</p>
  if (error)   return <ApiRequestError error={error} />

  return (
    <div>
      <Banner type="info" text={`The metrics for food prescriptions were last updated at ${formatDateMonthAndYear(data.brandsReport.prescriptionMetricsUpdatedAt)}`} />

      <div className="mt-5 grid grid-cols-3 gap-5">
        <CardWithMetric name="Total Brands"       value={data.brandsReport.brandsCount}                                      />
        <CardWithMetric name="Brands per country" values={data.brandsReport.brandsPerCountry} valueFontSizeClass={"text-xl"} />
        <CardWithMetric name="Total Products"     value={data.brandsReport.foodsCount}                                       />
      </div>

      <div className="mt-8">
        <WidgetTable titleComponent={<WidgetTitle exportEnabled={exportEnabled} toggleExportEnabled={toggleExportEnabled} selectAllBrandsToExport={selectAllBrandsToExport} setDownloadEnabled={setDownloadEnabled} sortByCriteria={sortByCriteria} setSortByCriteria={setSortByCriteria} />} columns={COLUMNS}>
          {sortBrands(data.brandsReport.brands, sortByCriteria).map(brand => (
            <WidgetTable.TableRow key={brand.id}>
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                  {exportEnabled &&
                    <div className="mr-3 flex items-center h-5">
                      <Checkbox
                        checked ={!!brandsToExport[brand.id]}
                        onChange={() => toggleBrandToExport(brand)}                      
                      />
                    </div>
                  }

                  <div className="flex-shrink-0">
                    <img className="w-12 object-cover" src={brand.logoUrl} />
                  </div>
                  <div className="ml-6">
                    <Link to={generateBrandUrl(brand)} className="text-sm font-medium text-gray-900 hover:underline">
                      {brand.name}
                    </Link>
                  </div>
                </div>
              </td>

              <WidgetTable.TableCellText text={generateCountriesText(brand.countries)}  />
              <WidgetTable.TableCellText text={brand.foodsCount}                        />
              <WidgetTable.TableCellText text={brand.foodPrescriptionsCount}            />
              <WidgetTable.TableCellText text={formatDateMonthAndYear(brand.createdAt)} />
            </WidgetTable.TableRow>
          ))}
        </WidgetTable>
      </div>

      {!!Object.keys(brandsToExport).length && downloadEnabled &&
        <BrandsFoodPrescriptionDataExporter
          brandIds       ={Object.keys(brandsToExport).map(id => parseInt(id))}
          onAfterDownload={() => {
            setExportEnabled(false)
            setDownloadEnabled(false)
            setBrandsToExport({})
          }}
          userData={userData}
        />
      }
    </div>
  )
}

export { Brands }
