import React, { useState } from "react"
import { useQuery } from "@apollo/client"
import { Select }   from "../components/Select"

import { ApiRequestError }                                        from "../components/ApiRequestError"
import { SchedulingEventsWidget }                                 from "../components/SchedulingEventsWidget"
import { formatDateMonthAndYear, generateRequestContextHeaders, SCHEDULING_EVENTS_QUERY } from "../Utils"

import { Button } from "../components/Button"

function SchedulingEvents({userData}) {
  const [companyId, setCompanyId] = useState(null)
  const [timeframe, setTimeframe] = useState(0)
  const [scheduledBy, setScheduledBy] = useState("All")
  const [companies, setCompanies] = useState([])
  const [selectedPatient, setSelectedPatient] = useState(null)
  const [previousState, setPreviousState] = useState({companyId: null, timeframe: null})

  const requestContext = generateRequestContextHeaders(userData)

  requestContext.variables = { companyId: null, scheduledBy: scheduledBy, beginDate: timeframeDate() }

  function companyOptions() {
    var result = [{name: "All Companies", value: null}]
    var companiesList = [...companies]

    companiesList.sort((a,b) => a.name < b.name).forEach((company) => {
      result.push({name: company.name, value: company.id})
    })

    return result
  }

  function scheduledByOptions() {
    return [{name: "Any Scheduler", value: "All"}, {name: "Scheduled by Professional", value: "Professional"}, {name: "Scheduled by Patient", value: "Patient"}]
  }

  function selectCompany(value) {
    let valueAsNumber = parseInt(value);

    if(isNaN(valueAsNumber)) {
      setCompanyId(null);
    } else {
      setCompanyId(valueAsNumber);
    }
  }

  function timeframeDate() {
    let result = new Date()

    if (timeframe == 4) {
      result = null;
    } else {
      if (timeframe == 0) {
	result.setDate(result.getDate() - 1);
      } else if (timeframe == 1) {
	result.setDate(result.getDate() - 7);
      }

      result = result.toISOString().substring(0, 10)
    }

    return result
  }

  function timeframeOptions() {
    return [
      { name: "Last 24 Hours", value: 0},
      { name: "Last Week", value: 1},
    ]
  }

  if (companyId) { requestContext.variables.companyId= companyId }
  if (scheduledBy) { requestContext.variables.scheduledBy = scheduledBy }

  const { loading, error, data } = useQuery(SCHEDULING_EVENTS_QUERY, requestContext)

  if (loading) return <p>Loading...</p>
  if (error)   return <ApiRequestError error={error} />

  if (companies.length === 0) {
    setCompanies(data.b2bReport.companies)
  }

  function schedulingEventsData() {
    var result = data.b2bReport.schedulingEvents

    if(selectedPatient != null) {
      result = data.b2bReport.schedulingEvents.filter((event) => event.user.account.email === selectedPatient)
    }

    return result
  }

  function onFilterByPatient(email, patientCompanyId) {
    if(email != null) {
      setPreviousState({companyId: companyId, timeframe: timeframe})
      setCompanyId(patientCompanyId)
      setTimeframe(4)
    } else {
      setCompanyId(previousState.companyId)
      setTimeframe(previousState.timeframe)
    }

    setSelectedPatient(email)
  }

  return (
    <>
      <div className="flex mb-[12px] space-x-4">
        <Select
          onChange={event => selectCompany(event.target.value)}
          options ={companyOptions()}
          value   ={companyId}
        />
        <Select
          onChange={event => setScheduledBy(event.target.value)}
          options ={scheduledByOptions()}
          value   ={scheduledBy}
        />
        <Select
          onChange={event => setTimeframe(event.target.value)}
          options ={timeframeOptions()}
          value   ={timeframe}
        />
      </div>
      { selectedPatient &&
        <div className="flex mb-[12px] space-x-4">
          <Button color="white" appendStyle="w-full" onClick={() => onFilterByPatient(null)} text={`Filtering by patient "${selectedPatient}", click here to dismiss filter.`} />
        </div>
      }
      <SchedulingEventsWidget
        schedulingEvents={schedulingEventsData()}
        title                   ="Requests"
        userData                ={userData}
        onFilterByPatient       ={(email, patientCompanyId) => onFilterByPatient(email, patientCompanyId)}
      />
    </>
  )
}

export { SchedulingEvents }
