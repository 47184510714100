import sortBy            from "lodash/sortBy"
import { useQuery, gql } from "@apollo/client"

import { ApiRequestError }               from "../components/ApiRequestError"
import { generateRequestContextHeaders } from "../Utils"
import { WidgetTable }                   from "../components/WidgetTable"

const QUERY = gql`
  query {
    b2bReport {
      professionals {
        id
        name
        avatarUrl
        patientsCount
        engagedPatientsInLast30DaysCount

        account {
          email
        }

        countryOfResidence {
          name
          emoji
        }
      }
    }
  }
`

const COLUMNS = [
  "Name", "Email", "Country", "Patients", "Patients engaged last 30 days"
]

function Professionals({userData}) {
  const { loading, error, data } = useQuery(QUERY, generateRequestContextHeaders(userData))

  if (loading) return <p>Loading...</p>;
  if (error)   return <ApiRequestError error={error} />

  return (
    <WidgetTable title="Professionals" columns={COLUMNS}>
      {sortBy(data.b2bReport.professionals, professional => professional.name.toLocaleUpperCase()).map(professional => (
        <WidgetTable.TableRow key={professional.id}>
          <WidgetTable.TableCellProfessional professional={professional} avatarSize={12} />
          <WidgetTable.TableCellEmail email={professional.account.email} />
          <WidgetTable.TableCellText text={`${professional.countryOfResidence.emoji} ${professional.countryOfResidence.name}`} />
          <WidgetTable.TableCellText text={professional.patientsCount} />
          <WidgetTable.TableCellText text={professional.engagedPatientsInLast30DaysCount} />
        </WidgetTable.TableRow>
      ))}
    </WidgetTable>
  )
}

export { Professionals }
