import { useQuery, gql } from "@apollo/client"
import { useState }      from "react"

import { ApiRequestError }                    from "../components/ApiRequestError"
import { Banner }                             from "../components/Banner"
import { BrandsFoodPrescriptionDataExporter } from "../components/BrandsFoodPrescriptionDataExporter"
import { DownloadData }                       from "../components/DownloadData"
import {
  generateRequestContextHeaders,
  RECIPES_PRESCRIPTIONS_CSV_REPORT_QUERY,
  Utils
} from "../Utils"
import { WidgetFileDownload } from "../components/WidgetFileDownload"

const QUERY = gql`
  query {
    brandsReport {
      prescriptionMetricsUpdatedAt
    }

    saasReport {
      updatedAt
    }

    recipesReport {
      prescriptionMetricsUpdatedAt
    }
  }
`

const B2B_EVENTS_QUERY = gql`
  query {
    b2bReport {
      b2bEventsCsvFile {
        fileContent
        fileName
      }
    }
  }
`

const PAYMENTS_REPORT_QUERY = gql`
  query {
    saasReport {
      paymentsCsvFile {
        fileContent
        fileName
      }
    }
  }
`

const PROFESSIONAL_REVIEWS_QUERY = gql`
  query {
    b2bReport {
      professionalReviewsCsvFile {
        fileContent
        fileName
      }
    }
  }
`

function Export({userData}) {
  const [downloadEnabled                   , setDownloadEnabled                   ] = useState(false)
  const [brandFoodsDownloadEnabled         , setBrandFoodsDownloadEnabled         ] = useState(false)
  const [paymentsDownloadEnabled           , setPaymentsDownloadEnabled           ] = useState(false)
  const [recipeMetricsDownloadEnabled      , setRecipeMetricsDownloadEnabled      ] = useState(false)
  const [professionalReviewsDownloadEnabled, setProfessionalReviewsDownloadEnabled] = useState(false)

  const { loading, error, data } = useQuery(QUERY, generateRequestContextHeaders(userData))

  if (loading) return <p>Loading...</p>
  if (error)   return <ApiRequestError error={error} />

  const today = new Date().toISOString().slice(0, 10)

  return (
    <>
      <Banner type="warning" text="These files may contain personal information about our users. Please be careful when sharing them with other people." />

      <div className="flex flex-col gap-8">
        <div>
          <WidgetFileDownload
            title         ="Events of professionals and patients"
            description   ="In this CSV file are listed a variety of events from professionals and patients interactions regarding the Nutrium Care program since its beginning until now. This operation can take several minutes. Please be patience and don't close this page until the download is completed."
            onClick       ={() => setDownloadEnabled(true)}
            buttonDisabled={downloadEnabled}
            badgeText     ="B2B"
            badgeColor    ="blue"
            dataUpdatedAt ={today}
          />

          {downloadEnabled &&
            <DownloadData
              userData       ={userData}
              onAfterDownload={() => setDownloadEnabled(false)}
              selectData     ={data => data.b2bReport.b2bEventsCsvFile}
              query          ={B2B_EVENTS_QUERY}
            />
          }
        </div>

        <div className="flex flex-col gap-8">
          <div>
            <WidgetFileDownload
              title         ="Professional reviews"
              description   ="In this CSV file are listed all the professional reviews made by the patients in the mobile app."
              onClick       ={() => setProfessionalReviewsDownloadEnabled(true)}
              buttonDisabled={professionalReviewsDownloadEnabled}
              badgeText     ="B2B"
              badgeColor    ="blue"
              dataUpdatedAt ={today}
            />

            {professionalReviewsDownloadEnabled &&
              <DownloadData
                userData       ={userData}
                onAfterDownload={() => setProfessionalReviewsDownloadEnabled(false)}
                selectData     ={(data) => data.b2bReport.professionalReviewsCsvFile}
                query          ={PROFESSIONAL_REVIEWS_QUERY}
              />
            }
          </div>
        </div>

        {/* <div>
          <WidgetFileDownload
            title         ="Payments report"
            description   ="In this CSV file are listed all the payments made by our professionals customers in the context of the SaaS business model since the beginning of 2020. This data powers the metrics shown in the SaaS metrics page of this dashboard."
            onClick       ={() => setPaymentsDownloadEnabled(true)}
            buttonDisabled={paymentsDownloadEnabled}
            badgeText     ="SaaS"
            badgeColor    ="green"
            dataUpdatedAt ={data.saasReport.updatedAt.slice(0, 10)}
          />

          {paymentsDownloadEnabled &&
            <DownloadData
              userData       ={userData}
              onAfterDownload={() => setPaymentsDownloadEnabled(false)}
              selectData     ={(data) => data.saasReport.paymentsCsvFile}
              query          ={PAYMENTS_REPORT_QUERY}
            />
          }
        </div> */}

        <div>
          <WidgetFileDownload
            title         ="Foods prescriptions report"
            description   ="In this CSV file are listed all the foods from all the brands and theirs corresponding prescription metrics from the professionals to theirs patients."
            onClick       ={() => setBrandFoodsDownloadEnabled(true)}
            buttonDisabled={brandFoodsDownloadEnabled}
            badgeText     ="Brands"
            badgeColor    ="yellow"
            dataUpdatedAt ={data.brandsReport.prescriptionMetricsUpdatedAt.slice(0, 10)}
          />

          {brandFoodsDownloadEnabled &&
            <BrandsFoodPrescriptionDataExporter
              userData       ={userData}
              onAfterDownload={() => setBrandFoodsDownloadEnabled(false)}
            />
          }
        </div>

        {/* TODO - The code for this export is still taking too much time and memory on the backend. We need to optimize this process before making it available in production */}
        {Utils.inDevelopmentEnvironment() &&
          <div>
            <WidgetFileDownload
              title         ="Recipes prescriptions report"
              description   ="In this CSV file are listed all the system recipes and theirs corresponding prescription metrics from the professionals to theirs patients. This operation can take several minutes. Please be patience and don't close this page until the download is completed."
              onClick       ={() => setRecipeMetricsDownloadEnabled(true)}
              buttonDisabled={recipeMetricsDownloadEnabled}
              badgeText     ="Recipes"
              badgeColor    ="orange"
              dataUpdatedAt ={data.recipesReport.prescriptionMetricsUpdatedAt.slice(0, 10)}
            />

            {recipeMetricsDownloadEnabled &&
              <DownloadData
                userData       ={userData}
                onAfterDownload={() => setRecipeMetricsDownloadEnabled(false)}
                selectData     ={(data) => data.recipesReport.prescriptionMetricsCsvFile}
                query          ={RECIPES_PRESCRIPTIONS_CSV_REPORT_QUERY}
              />
            }
          </div>
        }
      </div>
    </>
  )
}

export { Export }
