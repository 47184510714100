import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid"
import classNames                            from "classnames"
import React                                 from "react"

const PAGINATOR_SEPARATOR = "..."

function PaginatorElement({page, isCurrentPage, onClick, isSeparator}) {
  return (
    <>
      {isSeparator
        ?
          <span className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700">
            ...
          </span>
        :
          <a
            href="#"
            className={classNames(
              "relative inline-flex items-center border text-sm font-medium focus:z-20 px-4 py-2",
              isCurrentPage ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600" : "border-gray-300 bg-white text-gray-500 hover:bg-gray-50",
            )}
            onClick={() => onClick(page)}
          >
            {page}
          </a>        
      }
    </>
  )
}

function PaginatorActionIcon({isPrevious, isNext, onClick}) {
  return (
    <a
      href="#"
      className={`relative inline-flex items-center ${isPrevious ? "rounded-l-md" : "rounded-r-md"} border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20`}
      onClick={onClick}
    >
      {isPrevious ? <ChevronLeftIcon className="h-5 w-5" /> : <ChevronRightIcon className="h-5 w-5" /> }
    </a>
  )
}

function Paginator({currentPage, totalPages, totalRecords, recordsPerPage, onClick}) {
  const pages = [ 1 ]

  const middleFirst  = (currentPage > 2 && totalPages > 5) ? currentPage - 1 : 2
  const middleSecond = middleFirst + 1
  const middleThird  = middleSecond + 1
  const last         = totalPages

  const hasFirstSeparator  = middleFirst > 2
  const hasSecondSeparator = (last - middleThird) > 1

  if (hasFirstSeparator         ) { pages.push(PAGINATOR_SEPARATOR) }
  if (middleFirst <= totalPages ) { pages.push(middleFirst)         }
  if (middleSecond <= totalPages) { pages.push(middleSecond)        }
  if (middleThird <= totalPages ) { pages.push(middleThird)         }
  if (hasSecondSeparator        ) { pages.push(PAGINATOR_SEPARATOR) }
  if (last > middleThird        ) { pages.push(last)                }

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 px-6">
      <div className="flex flex-1 items-center justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing
            {totalRecords > 0 &&
              <>
                <span className="font-medium"> {((currentPage - 1) * recordsPerPage) + 1}</span> to <span className="font-medium">{Math.min(currentPage * recordsPerPage, totalRecords)}</span> of{' '}
              </>
            }
            <span className="font-medium"> {totalRecords}</span> results
          </p>
        </div>
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <PaginatorActionIcon isPrevious={true} onClick={() => onClick(Math.max(currentPage - 1, 1))} />
            {pages.map(page =>
              // TODO - Fix problem: encountered two children with the same key, `...`
              <PaginatorElement page={page} key={page} isCurrentPage={page === currentPage} onClick={onClick} isSeparator={page == PAGINATOR_SEPARATOR}/>
            )}
            <PaginatorActionIcon isNext={true} onClick={() => onClick(Math.min(currentPage + 1, Math.max(totalPages, 1)))} />
          </nav>
        </div>
      </div>
    </div>
  )
}

export { Paginator }
