import React from "react"

function TextArea({placeholder, onChange, value}) {
  return (
    <textarea
      className  ="text-xs w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
      placeholder={placeholder}
      onChange   ={event => onChange(event.target.value)}
      rows       ={5}
      value      ={value}
    />
  )
}

export { TextArea }
