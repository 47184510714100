import classNames        from "classnames"
import sortBy            from "lodash/sortBy"
import { useParams }     from "react-router-dom"
import { useQuery, gql } from "@apollo/client"
import { useState }      from "react"

import { ApiRequestError }                    from "../components/ApiRequestError"
import { Banner }                             from "../components/Banner"
import { BrandsFoodPrescriptionDataExporter } from "../components/BrandsFoodPrescriptionDataExporter"
import { Button }                             from "../components/Button"
import { Select }                             from "../components/Select"
import { WidgetTable }                        from "../components/WidgetTable"
import {
  formatDateMonthAndYear,
  generateCountriesText,
  generateRequestContextHeaders,
  Utils
} from "../Utils"

const QUERY = gql`
  query FetchBrand($id: Int!) {
    brandsReport {
      prescriptionMetricsUpdatedAt

      brand(id: $id) {
        id
        code
        name
        logoUrl
        createdAt
        foodsCount
        hiddenFoodsCount
        foodInformationSourceCodesFormatted

        countries {
          name
          emoji
        }  

        foods {
          id
          name
          searchable
          prescriptionsCount

          energyKcalPer100Grams
          proteinGramsPer100Grams
          fatGramsPer100Grams
          carbohydrateGramsPer100Grams
          sugarsGramsPer100Grams
          fiberGramsPer100Grams
        } 
      }
    }
  }
`

const COLUMNS = [
  "Name", "Status", "Prescriptions", "Energy", "Fat", "Carbs", "Sugars", "Protein", "Fiber"
]

const SORT_OPTIONS = [
  { value: 1, name: "Sort by name"          },
  { value: 2, name: "Sort by prescriptions" },
]

function WidgetTitle({sortByPrescriptions, setSortByPrescriptions, setDownloadEnabled}) {
  return (
    <div className="px-4 py-5 sm:px-6 flex items-center justify-center">
      <h3 className="text-lg leading-6 font-medium text-gray-900 flex-grow">Foods</h3>
      <div className="flex">
        <span>
          <Select
            onChange={event => setSortByPrescriptions(parseInt(event.target.value) == 2)}
            options ={SORT_OPTIONS}
            value   ={sortByPrescriptions ? 2 : 1}
          />
        </span>

        <span className="ml-4 relative z-0 inline-flex shadow-sm rounded-md">
          <Button onClick={() => setDownloadEnabled(true)} text="Export metrics" />
        </span>
      </div>
    </div>
  )
}

function sortFoods(foods, sortByPrescriptions) {
  if (sortByPrescriptions) {
    return sortBy(foods, food => food.prescriptionsCount).reverse()
  } else {
    return sortBy(foods, food => food.name.toLocaleUpperCase())
  }
}

function Brand({userData}) {
  const params  = useParams()
  const brandId = +params.id

  const [sortByPrescriptions, setSortByPrescriptions] = useState(false)
  const [downloadEnabled, setDownloadEnabled]         = useState(false)

  const requestContext     = generateRequestContextHeaders(userData)
  requestContext.variables = { id: brandId }

  const { loading, error, data } = useQuery(QUERY, requestContext)

  if (loading) return <p>Loading...</p>
  if (error)   return <ApiRequestError error={error} />

  const { brand } = data.brandsReport

  return (
    <div>
      <Banner type="info" text={`The metrics for food prescriptions were last updated at ${formatDateMonthAndYear(data.brandsReport.prescriptionMetricsUpdatedAt)}`} />

      <div className="mb-8 max-w-3xl mx-auto md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl">
        <div className="flex items-center space-x-5">
          <div className="flex-shrink-0">
            <div className="relative">
              <img className="w-24 object-cover" src={brand.logoUrl} />
            </div>
          </div>
          <div>
            <h1 className="text-2xl font-bold text-gray-900">{brand.name}</h1>
          </div>
        </div>
      </div>

      <div className="mb-8 bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h2 className="text-lg leading-6 font-medium text-gray-900">
            Brand details
          </h2>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Name</dt>
              <dd className="mt-1 text-sm text-gray-900">{brand.name}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Countries</dt>
              <dd className="mt-1 text-sm text-gray-900">{generateCountriesText(brand.countries)}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Created at</dt>
              <dd className="mt-1 text-sm text-gray-900">{formatDateMonthAndYear(brand.createdAt)}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Total foods</dt>
              <dd className="mt-1 text-sm text-gray-900">{brand.foodsCount}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Hidden foods</dt>
              <dd className="mt-1 text-sm text-gray-900">{brand.hiddenFoodsCount}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Database codes</dt>
              <dd className="mt-1 text-sm text-gray-900">{brand.foodInformationSourceCodesFormatted}</dd>
            </div>
          </dl>
        </div>
      </div>

      <WidgetTable titleComponent={<WidgetTitle sortByPrescriptions={sortByPrescriptions} setSortByPrescriptions={setSortByPrescriptions} setDownloadEnabled={setDownloadEnabled} />} columns={COLUMNS}>
        {sortFoods(brand.foods, sortByPrescriptions).map(food => (
          <WidgetTable.TableRow key={food.id}>
            <WidgetTable.TableCellText text={food.name} />

            <td className="px-6 py-4 whitespace-nowrap">
              <span className={classNames("px-2 inline-flex text-sm leading-5 font-semibold rounded-full", food.searchable ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800")}>
                {food.searchable ? "Active" : "Hidden"}
              </span>
            </td>

            <WidgetTable.TableCellText text={food.prescriptionsCount} />
            <WidgetTable.TableCellText text={Utils.formatNutritionValue(food.energyKcalPer100Grams, "kcal")} />
            <WidgetTable.TableCellText text={Utils.formatNutritionValue(food.fatGramsPer100Grams, "g")} />
            <WidgetTable.TableCellText text={Utils.formatNutritionValue(food.carbohydrateGramsPer100Grams, "g")} />
            <WidgetTable.TableCellText text={Utils.formatNutritionValue(food.sugarsGramsPer100Grams, "g")} />
            <WidgetTable.TableCellText text={Utils.formatNutritionValue(food.proteinGramsPer100Grams, "g")} />
            <WidgetTable.TableCellText text={Utils.formatNutritionValue(food.fiberGramsPer100Grams, "g")} />
          </WidgetTable.TableRow>
        ))}
      </WidgetTable>

      {downloadEnabled &&
        <BrandsFoodPrescriptionDataExporter
          brandIds       ={[brandId]}
          onAfterDownload={() => setDownloadEnabled(false)}
          userData       ={userData}
        />
      }
    </div>
  )
}

export { Brand }
