import { InformationCircleIcon } from '@heroicons/react/solid'
import sortBy                    from "lodash/sortBy"
import { useQuery, gql }         from "@apollo/client"

import { ApiRequestError }                from "../components/ApiRequestError"
import { CardWithMetric }                 from "../components/CardWithMetric"
import { SectionTitle }                   from "../components/SectionTitle"
import { WidgetTable }                    from "../components/WidgetTable"
import {
  calculatePercentage,
  formatValueInEuros,
  generateRequestContextHeaders,
} from "../Utils"

const QUERY = gql`
  query {
    b2bReport {
      companiesCount
      activeCompaniesCount

      activeCompaniesPerCountry {
        country {
          emoji
        }
        count
      }

      professionalsCount
      professionalsPerCountry {
        country {
          emoji
        }
        count
      }

      patientsCount
      activePatientsCount
      activePatientsPerCountry {
        country {
          emoji
        }
        count
      }

      companies {
        id
        name
        logoUrl
      }

      engagedPatientsReport {
        count
        inLast30DaysCount

        allTimeCostInEuros
        yearToDateCostInEuros

        yearToDateCostsPerCompany {
          companyId
          costInEuros
        }

        engagedPatientsReportPerMonth {
          monthIso8601
          monthFormatted
          currentMonth

          count
          costInEuros

          countVariation
          costVariation

          companies {
            companyId

            count
            costInEuros

            countVariation
            costVariation
          }
        }
      }
    }
  }
`

const FOOTNOTE = "* Metrics are subject to change until the end of this month"

function Home({userData}) {
  const { loading, error, data } = useQuery(QUERY, generateRequestContextHeaders(userData))

  if (loading) return <p>Loading...</p>
  if (error)   return <ApiRequestError error={error} />

  const { b2bReport } = data

  const companiesNames = b2bReport.companies.map(company => company.name)
  const columnsLogos   = [null, null].concat(b2bReport.companies.map(company => company.logoUrl))
  const columns        = ["Month", "Total Patients engaged"].concat(companiesNames)
  const columnsCosts   = ["Month", "Total Cost"].concat(companiesNames)

  const activePatientsPerCountry = sortBy(b2bReport.activePatientsPerCountry, perCountryReport => -perCountryReport.count)
  const professionalsPerCountry  = sortBy(b2bReport.professionalsPerCountry, perCountryReport => -perCountryReport.count)

  return (
    <div>
      <div className="rounded-md bg-blue-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">Pages are being cached to improve navigation. Press <kbd>Cmd</kbd> + <kbd>R</kbd>  to ensure that you are seeing the latest data available.</p>
          </div>
        </div>
      </div>

      <div className="mt-8">
        <SectionTitle title="Companies metrics" />

        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          <CardWithMetric name="Total Companies" value={b2bReport.companiesCount} />
          <CardWithMetric name="Active Companies" value={b2bReport.activeCompaniesCount} badgeValue={calculatePercentage(b2bReport.activeCompaniesCount, b2bReport.companiesCount)} />
          <CardWithMetric name="Active Companies per country" values={b2bReport.activeCompaniesPerCountry} valueFontSizeClass={"text-xl"} valuesTotal={b2bReport.activeCompaniesCount} />
        </dl>
      </div>

      <div className="mt-8">
        <SectionTitle title="Patients metrics" />

        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          <CardWithMetric name="Total Patients" value={b2bReport.patientsCount} />
          <CardWithMetric name="Active Patients (from active companies)" value={b2bReport.activePatientsCount} badgeValue={calculatePercentage(b2bReport.activePatientsCount, b2bReport.patientsCount)} />
          <CardWithMetric name="Active Patients per country" values={activePatientsPerCountry} valueFontSizeClass={"text-xl"} />
        </dl>
      </div>

      <div className="mt-8">
        <SectionTitle title="Professionals metrics" />

        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          <CardWithMetric name="Total Professionals" value={b2bReport.professionalsCount} />
          <CardWithMetric name="Professionals per country" values={professionalsPerCountry} valueFontSizeClass={"text-xl"} valuesTotal={b2bReport.professionalsCount} />
        </dl>
      </div>

      <div className="mt-8">
        <SectionTitle title="Engagement metrics" />

        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          <CardWithMetric name="Total Patients Engaged" value={b2bReport.engagedPatientsReport.count} />
          <CardWithMetric name="Patients engaged in last 30 days" value={b2bReport.engagedPatientsReport.inLast30DaysCount} />
        </dl>

        <div className="mt-8" />

        <WidgetTable columns={columns} columnsLogos={columnsLogos} footnote={FOOTNOTE}>
          {b2bReport.engagedPatientsReport.engagedPatientsReportPerMonth.slice(0).reverse().map(report => (
            <WidgetTable.TableRow key={report.monthIso8601}>
              <WidgetTable.TableCellText text={report.monthFormatted} />
              <WidgetTable.TableCellVariaton text={report.count} value={report.countVariation} currentMonth={report.currentMonth} />

              {report.companies.map(companyReport =>
                <WidgetTable.TableCellVariaton key={companyReport.companyId} text={companyReport.count} value={companyReport.countVariation} currentMonth={report.currentMonth} />
              )}
            </WidgetTable.TableRow>
          ))}
        </WidgetTable>
      </div>

      <div className="mt-8">
        <SectionTitle title="Cost analysis" />

        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          <CardWithMetric name="Total Cost" value={formatValueInEuros(b2bReport.engagedPatientsReport.allTimeCostInEuros, true)} />
          <CardWithMetric name="Year To Date Cost" value={formatValueInEuros(b2bReport.engagedPatientsReport.yearToDateCostInEuros, true)} />
        </dl>

        <div className="mt-8" />

        <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
          <dt className="text-sm font-medium text-gray-500 truncate">Year To Date Costs per company</dt>
          <dd className="grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-8 mt-6 text-lg font-semibold text-gray-900">
            {sortBy(b2bReport.engagedPatientsReport.yearToDateCostsPerCompany, companyReport => companyReport.costInEuros).reverse().map(companyReport =>
                <div key={companyReport.companyId} className="flex items-center mr-10">
                  <img className="mr-3 w-8 rounded-full object-cover" src={b2bReport.companies.find(company => company.id === companyReport.companyId).logoUrl } />
                  <span>{formatValueInEuros(companyReport.costInEuros, true)}</span>
                  <span className="ml-3 px-2.5 py-0.5 rounded-full text-base font-medium bg-sky-100 text-sky-800">
                    {calculatePercentage(companyReport.costInEuros, b2bReport.engagedPatientsReport.yearToDateCostInEuros)} %
                  </span>
                </div>
              )
            }
          </dd>
        </div>

        <div className="mt-8" />

        <WidgetTable columns={columnsCosts} columnsLogos={columnsLogos} footnote={FOOTNOTE}>
          {b2bReport.engagedPatientsReport.engagedPatientsReportPerMonth.slice(0).reverse().map(report => (
            <WidgetTable.TableRow key={report.monthIso8601}>
              <WidgetTable.TableCellText text={report.monthFormatted} />
              <WidgetTable.TableCellVariaton text={formatValueInEuros(report.costInEuros, true)} value={report.costVariation} currentMonth={report.currentMonth} neutralEveryBadgeColor={true} />

              {report.companies.map(companyReport =>
                <WidgetTable.TableCellVariaton key={companyReport.companyId} text={formatValueInEuros(companyReport.costInEuros, true)} value={companyReport.costVariation} currentMonth={report.currentMonth} neutralEveryBadgeColor={true} />
              )}
            </WidgetTable.TableRow>
          ))}
        </WidgetTable>
      </div>
    </div>
  )
}

export { Home }
