import { useQuery, gql, useMutation } from "@apollo/client"
import React, { useRef, useState }      from "react"

import { ApiRequestError }                    from "../components/ApiRequestError"
import { Banner }                             from "../components/Banner"
import { BrandsFoodPrescriptionDataExporter } from "../components/BrandsFoodPrescriptionDataExporter"
import { DownloadData }                       from "../components/DownloadData"
import {
  generateRequestContextHeaders,
  downloadCSVFile
} from "../Utils"
import { WidgetFileDownload } from "../components/WidgetFileDownload"
import { Select } from "../components/Select"
import { Checkbox } from "../components/Checkbox"
import { Button } from "../components/Button"
import { ArrowDownIcon, ArrowRightIcon, ArrowUpIcon, ClipboardCopyIcon, DownloadIcon, MinusCircleIcon, PlusCircleIcon, TranslateIcon } from "@heroicons/react/solid"
import { CardVerbatimText } from "../components/CardVerbatimText"
import { TextArea } from "../components/TextArea"
import papaparse from "papaparse"
import { Input } from "../components/Input"

const GENERATE_TRANSLATIONS_MUTATION = gql`
  mutation($fromLanguageId: Int!, $toLanguageIds: [Int!]!, $texts: [String!]!, $requestReview: Boolean, $context: String) {
    generateTranslations(input: { fromLanguageId: $fromLanguageId, toLanguageIds: $toLanguageIds, texts: $texts, requestReview: $requestReview, context: $context }) {
      translationsData {
        fromLanguageId
        toLanguageId
        originalTexts
        translatedTexts
      }
    }
  }
`

const LANGUAGES = {
  portuguese:           0,
  english:              1,
  brazilian_portuguese: 2,
  spanish:              3,
  french:               4,
  italian:              5,
  german:               6
}

const languageSelectOptions = [
  { value: LANGUAGES.portuguese,           emoji: "🇵🇹", name: "🇵🇹 Portuguese"  },
  { value: LANGUAGES.english,              emoji: "🇬🇧", name: "🇬🇧 English"     },
  { value: LANGUAGES.brazilian_portuguese, emoji: "🇧🇷", name: "🇧🇷 Brazilian"   },
  { value: LANGUAGES.spanish,              emoji: "🇪🇸", name: "🇪🇸 Spanish"     },
  { value: LANGUAGES.french,               emoji: "🇫🇷", name: "🇫🇷 French"      },
  { value: LANGUAGES.italian,              emoji: "🇮🇹", name: "🇮🇹 Italian"     },
  { value: LANGUAGES.german,               emoji: "🇩🇪", name: "🇩🇪 German"      },
]

function onLanguageCheckboxChange(languageId, toLanguageIds, setToLanguageIds) {
  let languageIds

  if (toLanguageIds.includes(languageId)) {
    languageIds = toLanguageIds.filter(toLanguageId => toLanguageId != languageId)
  } else {
    languageIds = [...toLanguageIds, languageId]
  }

  setToLanguageIds(languageIds)
}

function Translations({userData}) {
  const inputRef = useRef()

  const [fromLanguageId, setFromLanguageId] = useState(LANGUAGES.portuguese)
  const [toLanguageIds , setToLanguageIds ] = useState([LANGUAGES.english])
  const [textAreas     , setTextAreas     ] = useState([""])

  const [requestReview, setRequestReview] = useState(false)
  const [context      , setContext      ] = useState("")

  const requestContext                                          = generateRequestContextHeaders(userData)
  const [generateTranslationsRequest, { data, loading, error }] = useMutation(GENERATE_TRANSLATIONS_MUTATION, requestContext)
  const translationsData                                        = data?.generateTranslations?.translationsData

  const createTextArea = () => {
    setTextAreas([...textAreas, ""])
  }

  const setTextAreaText = (textAreaIndex, value) => {
    textAreas[textAreaIndex] = value

    setTextAreas([...textAreas])
  }

  const generateTranslations = () => {
    const texts                 = textAreas.map(text => text.trim()).filter(text => text.length)
    const filteredToLanguageIds = toLanguageIds.filter(languageId => languageId != fromLanguageId)

    if ((filteredToLanguageIds.length > 0) && (texts.length > 0)) {
      const variables = {
        fromLanguageId: fromLanguageId,
        toLanguageIds:  filteredToLanguageIds,
        texts,
      }

      if (requestReview) {
        variables.requestReview = true
        variables.context       = context.trim()
      }

      generateTranslationsRequest({ variables })
    }
  }

  const importCsvTextsFile = (event) => {
    const file   = event.target.files[0]
    const reader = new FileReader()

    reader.onload = (e) => {
      const { data }  = papaparse.parse(e.target.result)
      const texts     = []

      data.forEach(row => texts.push(row[0]))

      if (texts.length) {
        setTextAreas(texts)
      }
    }

    reader.readAsText(file)
  }

  const downloadCSVTranslationsFile = () => {
    const csvData = []
    const headers = [ languageSelectOptions[fromLanguageId].name ]

    translationsData.forEach(translationData => {
      headers.push( languageSelectOptions[translationData.toLanguageId].name )
    })
    csvData.push(headers)

    translationsData[0].originalTexts.forEach((originalText, index) => {
      const csvRow = [ originalText ]

      translationsData.forEach(translationData => {
        csvRow.push( translationData.translatedTexts[index] )
      })

      csvData.push(csvRow)
    })

    const fileObject = {
      filename:    `translations-${new Date().toISOString().replace(/T|\:/g, "-").slice(0, 19)}.csv`,
      fileContent: papaparse.unparse(csvData)
    }

    downloadCSVFile(null, fileObject)
  }

  return (
    <>
      <Banner type="info" text="The translation are performed by the DeepL API." />

      <div className="grid grid-cols-[0.5fr_1fr]-- grid-cols-2 gap-8 mb-8">
        <div>
          <p className="font-medium text-gray-700 mb-4">Translate from</p>
          <Select
            onChange={event => setFromLanguageId(+event.target.value)}
            value   ={fromLanguageId}
            options ={languageSelectOptions}
          />
        </div>

        <div>
          <p className="font-medium text-gray-700 mb-4">Translate to</p>
          <div className="grid grid-cols-3 items-center gap-2">
            {languageSelectOptions
              .filter(languageData => languageData.value != fromLanguageId)
              .map(languageData =>
                <div className="flex items-center gap-2" key={languageData.value}>
                  <Checkbox
                    checked ={toLanguageIds.includes(languageData.value)}
                    onChange={() => onLanguageCheckboxChange(languageData.value, toLanguageIds, setToLanguageIds)}
                  />
                  <span className="text-md">{languageData.name}</span>
                </div>
              )
            }
          </div>
        </div>

        <div className="col-span-2">
          <div className="flex items-center gap-2">
            <Checkbox
              checked ={requestReview}
              onChange={() => setRequestReview(!requestReview)}
            />
            <span className="text-md min-w-[200px]">Request review in Slack</span>
            {requestReview &&
              <Input placeholder="(Optional) Provide additional context to the translation team.." onChange={setContext} value={context} />
            }
          </div>
        </div>

        <div className="col-span-2 grid grid-cols-3 gap-8">
          {textAreas.map((textArea, index) =>
            <TextArea
              key        ={index}
              onChange   ={value => setTextAreaText(index, value)}
              placeholder="Write the text to translate..."
              value      ={textArea}
            />
          )}
        </div>

        <div className="col-span-2 grid grid-cols-3 gap-8">
          <Button Icon={PlusCircleIcon} onClick={createTextArea} text="Add new text block" />
          <Button Icon={ArrowUpIcon} onClick={() => inputRef.current.click()} text="Import texts from CSV" />
          <input type="file" accept=".csv" onChange={importCsvTextsFile} hidden={true} ref={inputRef} />
          <Button color="indigo" disabled={!toLanguageIds.length} loading={loading} Icon={TranslateIcon} onClick={generateTranslations} text={loading ? null : "Translate"} />
        </div>

        {translationsData &&
          <>
            <div className="bg-white shadow rounded-lg col-span-2">
              <div className="px-4 py-5 sm:px-6 flex items-center justify-center">
                <h3 className="text-lg leading-6 font-medium text-gray-900 min-w-[150px] grow">Translations Results</h3>
                <Button Icon={DownloadIcon} onClick={downloadCSVTranslationsFile} text="Download results CSV file" />
              </div>
            </div>

            <div className="col-span-2 -mt-8">
              {translationsData.map(translationData =>
                <div className="grid grid-cols-[1fr_0.25fr_1fr] gap-4 mt-8" key={translationData.toLanguageId}>
                  <p className="text-center font-medium text-gray-700">
                    {languageSelectOptions[translationData.fromLanguageId].name}
                  </p>
                  <span></span>

                  <p className="text-center font-medium text-gray-700">
                    {languageSelectOptions[translationData.toLanguageId].name}
                  </p>

                  {translationData.originalTexts.map((originalText, textIndex) =>
                    <React.Fragment key={textIndex}>
                      <CardVerbatimText color="sky" text={originalText} />
                      <div className="flex items-center justify-center">
                        <ArrowRightIcon className="h-4 w-4" />
                      </div>
                      <CardVerbatimText color="orange" text={translationData.translatedTexts[textIndex]} />
                    </React.Fragment>
                  )}
                </div>
              )}
            </div>
          </>
        }
      </div>
    </>
  )
}

export { Translations }
