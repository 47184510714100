import React, { useState } from "react"
import sortBy              from "lodash/sortBy"
import { useQuery, gql }   from "@apollo/client"
import {
  ChevronLeftIcon,
  ChevronRightIcon
} from "@heroicons/react/solid"

import { ApiRequestError }               from "../components/ApiRequestError"
import { generateRequestContextHeaders } from "../Utils"
import { WidgetTable }                   from "../components/WidgetTable"
import { Banner }                        from "../components/Banner"
import { Button }                        from "../components/Button"

const ACTIVE_STATUS = "Active"

const QUERY = gql`
  query($weeksAgo: Int) {
    challengesReport {
      challengesPerWeek(weeksAgo: $weeksAgo) {
        dateInterval {
          beginDate
          endDate
        }
        challengesPerCompany {
          company {
            id
            name
            logoUrl
            engagedPatientsInLast30DaysCount
            patientsOnMobileAppInLast30DaysCount
          }
          stepsGoal
          minutesGoal
          individualStarsGoal
          communityStarsGoal
          communityStarsReached
          numberOfPatients
          numberOfParticipants
        }
      }
    }
  }
`

const COLUMNS = [
  "Company", "Weekly Steps Goal", "Weekly Minutes Goal", "Weekly Individual Stars Goal", "Weekly Community Stars Goal", "Weekly Community Stars Reached",
  "Number of Patients", "Number of Participants", "Patients Engaged Last 30 days", "Patients on mobile app in the last 30 days"
]

function sortCompanies(companies) {
  return sortBy(
    companies,
    company => `${company.status == ACTIVE_STATUS ? 1 : 0}${String(company.patientsCount).padStart(9, '0')}`
  ).reverse()
}

const WidgetTitle = ( {title, dateString, weeksAgo, previousWeekHandler, nextWeekHandler, currentWeekHandler} ) => {
  let string

  switch (weeksAgo) {
    case -1:
      string = 'Next week'
      break
    case 0:
      string = 'This week'
      break
    case 1:
      string = 'Last week'
      break
    default:
      string = weeksAgo > 0 ? `${weeksAgo} weeks ago` : `${-weeksAgo} weeks from now`
  }

  return (
  <div className="px-4 py-5 sm:px-6 flex items-center justify-between">
    <div>
        <h3 className="text-lg font-medium leading-6 text-gray-900">{ title }</h3>
        <span className="text-xs text-gray-500">
          { dateString }
        </span>
      </div>
      <div className="flex items-center rounded-md shadow-sm sm:items-stretch">
        <Button
          type="button"
          onClick={previousWeekHandler}
          groupPosition="left"
          color="white"
          text={<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />}
        />
        <Button
          type="button"
          noAction
          groupPosition="middle"
          color="white"
          appendStyle="hidden sm:block"
          text={string}
        />
        <Button
          type="button"
          onClick={nextWeekHandler}
          groupPosition="right"
          color="white"
          text={<ChevronRightIcon className="h-5 w-5" aria-hidden="true" />}
        />
        <span className="w-2" />
        <Button
          type="button"
          onClick={currentWeekHandler}
          color="white"
          appendStyle="hidden sm:block"
          text="Current week"
        />
      </div>
    </div>
  )
}

const formatDate = (dateString) => {
  if (!dateString){
    return null;
  }
  return new Date(dateString).toLocaleDateString()
}

function Challenges({userData}) {
  const [weeksAgo, setWeeksAgo] = useState(0)

  const requestContext = generateRequestContextHeaders(userData)

  if (weeksAgo) { requestContext.variables = { weeksAgo } }

  const { loading, error, data } = useQuery(QUERY, requestContext)

  const beginDate  = formatDate(data?.challengesReport.challengesPerWeek.dateInterval.beginDate)
  const endDate    = formatDate(data?.challengesReport.challengesPerWeek.dateInterval.endDate)
  const dateString = beginDate && endDate ? `${beginDate} - ${endDate}` : "Loading..."

  const TableContent = () => {
    if (loading) return <WidgetTable.TableRow key='loading'><WidgetTable.TableCellText text="Loading..." /></WidgetTable.TableRow>
    if (error)   return <WidgetTable.TableRow key='error'><WidgetTable.TableCellText text={<ApiRequestError error={error} />} /></WidgetTable.TableRow>
    if (data.challengesReport.challengesPerWeek.challengesPerCompany.length === 0) {
      return ( <Banner type="info" text="There are no companies with challenges enabled"/> )
    }
    return sortCompanies(data.challengesReport.challengesPerWeek.challengesPerCompany).map(challenge => (
      <WidgetTable.TableRow key={challenge.company.id}>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <img className="w-8 rounded-full object-cover" src={challenge.company.logoUrl} alt={challenge.company.name}/>
            </div>
            <div className="ml-4">
              <span className="text-sm font-medium text-gray-900">{challenge.company.name}</span>
            </div>
          </div>
        </td>

        <WidgetTable.TableCellText text={challenge.stepsGoal} />
        <WidgetTable.TableCellText text={challenge.minutesGoal} />
        <WidgetTable.TableCellText text={challenge.individualStarsGoal} />
        <WidgetTable.TableCellText text={challenge.communityStarsGoal} />
        <WidgetTable.TableCellText text={challenge.communityStarsReached} />
        <WidgetTable.TableCellText text={challenge.numberOfPatients} />
        <WidgetTable.TableCellText text={challenge.numberOfParticipants} />
        <WidgetTable.TableCellText text={challenge.company.engagedPatientsInLast30DaysCount} />
        <WidgetTable.TableCellText text={challenge.company.patientsOnMobileAppInLast30DaysCount} />
      </WidgetTable.TableRow>
    ))
  }

  return (
    <WidgetTable columns={COLUMNS} titleComponent={
      <WidgetTitle
        title="Challenges"
        dateString={dateString}
        weeksAgo={weeksAgo}
        previousWeekHandler={() => setWeeksAgo(weeksAgo + 1)}
        nextWeekHandler={() => setWeeksAgo(weeksAgo - 1)}
        currentWeekHandler={() => setWeeksAgo(0)}
      />
    }>
      <TableContent />
    </WidgetTable>
  )
}

export { Challenges }
