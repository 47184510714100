import ChartJS                  from "chart.js/auto"
import { Component, createRef } from "react"

class Chart extends Component {
  chartRef = createRef()

  componentDidMount() {  
    const data = {
      labels:   this.props.labels,
      datasets: this.props.datasets.map(dataset => (
        {
          ...dataset,
          pointStyle:      "circle",
          pointRadius:      8,
          pointHoverRadius: 15,
        }
      ))
    }
  
    const config = {
      type:    this.props.type || "line",
      data:    data,
      options: {}
    }

    new ChartJS(this.chartRef.current, config)
  }

  render() {
    return <canvas ref={this.chartRef} className="px-4 py-5" height="250px" />
  }
}

export { Chart }
