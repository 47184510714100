import React, { useState } from "react"

function Input({error, initialValue, label, onChange, placeholder, required, type}) {
  const [value, setValue] = useState(initialValue)

  const inputElement = (
    <input
      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
      onChange={event => {
        const { value } = event.target
        setValue(value)
        onChange(value)
      }}
      placeholder={placeholder}
      required   ={!!required}
      type       ={type}
      value      ={value}
    />
  )
  const errorElement = (
    !!(error?.length) &&
      <p className="mt-2 text-red-600">
        {error}
      </p>
  )

  return (
    label
      ?
        <div>
          <label className="block font-medium text-gray-700">{label}</label>
          <div className="mt-1">
            {inputElement}
          </div>
          {errorElement}
        </div>
      :
        <>
          {inputElement}
          {errorElement}
        </>
  )
}

Input.defaultProps = {
  type: "text"
}

export { Input }
