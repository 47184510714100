import React, { useState } from 'react'

import { Button } from '../components/Button'
import { Input }  from "../components/Input"

const ERROR_MESSAGE   = "Your credentials are wrong."
const REQUEST_OPTIONS = {
  method: 'POST',
  headers: {
    'Accept'      : 'application/json',
    'Content-Type': 'application/json'
  }
}

function Login({setUserData}) {
  const [email,     setEmail    ] = useState("")
  const [password,  setPassword ] = useState("")
  const [code,      setCode     ] = useState("")
  const [error,     setError    ] = useState("")
  const [loginStep, setLoginStep] = useState(1)
  const [tokenMFA,  setTokenMFA ] = useState("")

  const performFirstStepLoginRequest = (event) => {
    const options = {...REQUEST_OPTIONS}
    options.body  = JSON.stringify({email, password})

    fetch('/rails/first_step_sign_in', options)
      .then(response => {
        if (response.status == 200) {
          response.json().then(responseBody => {
            setTokenMFA(responseBody.MFAToken)
            setError("")
            setLoginStep(2)
          })
        } else {
          setError(ERROR_MESSAGE)
        }
      })

    event.preventDefault()
  }

  const performSecondStepLoginRequest = (event) => {
    const options                    = {...REQUEST_OPTIONS}
    options.headers['Authorization'] = `Bearer ${tokenMFA}`
    options.body                     = JSON.stringify({mfa_code: code})

    fetch('/rails/second_step_sign_in', options)
      .then(response => {
        if (response.status == 200) {
          response.json().then(responseBody => {
            setUserData(responseBody)
            localStorage.setItem("userData", JSON.stringify(responseBody))
          })
        } else {
          setError(ERROR_MESSAGE)
        }
      })

    event.preventDefault()
  }

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src      ="https://app.nutrium.com/images/logos/lettering-logo.png"
          />
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            {loginStep == 1 &&
              <form className="space-y-6" onSubmit={performFirstStepLoginRequest}>
                <Input
                  initialValue={email}
                  label       ="Email address"
                  onChange    ={value => setEmail(value)}
                  required    ={true}
                  type        ="email"
                />
                <Input
                  error       ={error}
                  initialValue={password}
                  label       ="Password"
                  onChange    ={value => setPassword(value)}
                  required    ={true}
                  type        ="password"
                />
                <Button color="indigo" fullWidth={true} text="Sign in" type="submit" />
              </form>
            }

            {loginStep == 2 &&
              <form className="space-y-6" onSubmit={performSecondStepLoginRequest}>
                <Input
                  error       ={error}
                  initialValue={code}
                  label       ="Enter the code that you received in your phone"
                  onChange    ={value => setCode(value)}
                  required    ={true}
                />
                <Button color="indigo" fullWidth={true} text="Sign in" type="submit" />
              </form>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export { Login }
