import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid'
import classNames                         from "classnames"

import { calculatePercentage, formatNumericValue, formatToPercentageWithDecimal } from "../Utils"

const VALUE_VARIATION_COLORS = [
  ["bg-red-100   text-red-800"  , "text-red-500"  ],
  ["bg-green-100 text-green-800", "text-green-500"]
]

function ValueVariation({ value, invertColors }) {
  let valueAdjusted = value - 1;

  const successColor = invertColors ? VALUE_VARIATION_COLORS[0] : VALUE_VARIATION_COLORS[1]
  const failureColor = invertColors ? VALUE_VARIATION_COLORS[1] : VALUE_VARIATION_COLORS[0]
  const neutralColor = "bg-gray-100 text-gray-800"

  return (
    <div className={classNames(
      valueAdjusted > 0 ? successColor[0] : valueAdjusted < 0 ? failureColor[0] : neutralColor,
      'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0 ml-3'
    )}>
      {valueAdjusted > 0 ? (
        <ArrowSmUpIcon className={classNames("-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5", successColor[1])} />
      ) : valueAdjusted < 0 ? (
        <ArrowSmDownIcon className={classNames("-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5", failureColor[1])} />
      ) :
        <></>
      }

      {`${formatToPercentageWithDecimal(Math.abs(valueAdjusted))} %`}
    </div>
  )
}

function CardWithMetric({ name, value, values, badgeValue, badgeText, valuesTotal, twoValues, valueVariation, valueVariationInvertColors, valueFontSizeClass, formatValues }) {
  return (
    <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
      <dt className="text-sm font-medium text-gray-500 truncate">{name}</dt>
      <dd className={`flex items-center mt-1 ${valueFontSizeClass || "text-3xl"} font-semibold text-gray-900`}>
        {value && <span>{value}</span>}

        {badgeValue &&
          <div className="ml-3 px-2.5 py-0.5 rounded-full text-base font-medium bg-sky-100 text-sky-800">
            {badgeValue} {badgeText || "%"}
          </div>
        }

        {valueVariation &&
          <ValueVariation value={valueVariation} invertColors={valueVariationInvertColors} />
        }

        {values &&
          values.map(value =>
            // TODO - Make this generic and not tied to a country or a language
            <div key={value.country?.emoji || value.language.emoji} className="flex items-center mr-4">
              <span className="mr-2">{value.country?.emoji || value.language.emoji}</span>
              <span>{formatValues ? formatNumericValue(value.count) : value.count}</span>
              {valuesTotal &&
                <span className="ml-2 px-2.5 py-0.5 rounded-full text-base font-medium bg-sky-100 text-sky-800">
                  {calculatePercentage(value.count, valuesTotal)} %
                </span>}
            </div>
          )
        }

        {twoValues &&
          <>
            <span className='text-xl'>{twoValues[0]}</span>
            <span className='text-xl mx-2 text-gray-400'>/</span>
            <span className='text-xl'>{twoValues[1]}</span>
          </>
        }
      </dd>
    </div>
  )
}

export { CardWithMetric }
