import React, { useEffect } from "react"
import { useQuery }         from "@apollo/client"

import { downloadCSVFile, generateRequestContextHeaders } from "../Utils"
import { ApiRequestError } from "./ApiRequestError"

function DownloadData({query, userData, variables, onAfterDownload, selectData}) {
  const requestContext     = generateRequestContextHeaders(userData)
  requestContext.variables = variables || {}

  const { loading, error, data } = useQuery(query, requestContext)

  useEffect(() => {
    if (data) { 
      downloadCSVFile(selectData(data))
      onAfterDownload()
    }
  }, [Boolean(data)])

  if (loading) return <></>
  if (error)   return <ApiRequestError error={error} />

  return <></>
}

export { DownloadData }
