import ChartJS                  from "chart.js/auto"
import { Component, createRef } from "react"

class PieChart extends Component {
  chartRef = createRef()

  componentDidMount() {  
    const data = {
      labels:   this.props.labels,
      datasets: this.props.datasets.map(dataset => ({ ...dataset }))
    }

    const config = {
      type:    "doughnut",
      data:    data,
      options: {}
    }

    new ChartJS(this.chartRef.current, config)
  }

  render() {
    return <canvas ref={this.chartRef} className="py-2" width="150px" />
  }
}

export { PieChart }
