import { ExclamationIcon, InformationCircleIcon } from "@heroicons/react/solid"
import React                                      from "react"

const DATA = {
  info:    [ "bg-blue-50"  , "text-blue-400"  , "text-blue-700"  , InformationCircleIcon ],
  warning: [ "bg-yellow-50", "text-yellow-400", "text-yellow-700", ExclamationIcon       ],
  danger:  [ "bg-red-50"   , "text-red-400"   , "text-red-700"   , InformationCircleIcon ],
}

function Banner({ type, text, compact }) {
  const [backgroundColor, iconColor, textColor, Icon ] = DATA[type]

  return (
    <div className={`rounded-md ${backgroundColor} ${compact ? "" : "p-4 mb-5"}`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon className={`h-5 w-5 ${iconColor}`} />
        </div>
        <div className={`${compact ? "ml-1" : "ml-3"} flex-1 flex justify-between`}>
          <p className={`text-sm ${textColor}`}>{text}</p>
        </div>
      </div>
    </div>
  )
}

export { Banner }
