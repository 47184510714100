import classNames from "classnames"
import { Link }   from "react-router-dom"

function TabsNav({ tabs, onClick }) {
  return (
    <nav className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">
      {tabs.map((tab, tabIndex) => (
        <Link
          key      ={tab.name}
          to       ={tab.href}
          className={classNames(
            tab.current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
            tabIndex === 0 ? 'rounded-l-lg' : '',
            tabIndex === tabs.length - 1 ? 'rounded-r-lg' : '',
            'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10'
          )}
          onClick={() => onClick(tab)}
        >
          <span>{tab.name}</span>
          <span className={classNames(tab.current ? 'bg-indigo-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5')} />
        </Link>
      ))}
    </nav>
  )
}

export { TabsNav }
