import { useParams }       from "react-router-dom"
import moment              from "moment"
import sortBy              from "lodash/sortBy"
import { useQuery, gql }   from "@apollo/client"
import { UsersIcon }       from "@heroicons/react/solid"
import { useState }        from "react"

import { ApiRequestError }   from "../components/ApiRequestError"
import { Banner }            from "../components/Banner"
import { Button }            from "../components/Button"
import { CardWithMetric }    from "../components/CardWithMetric"
import { SectionTitle }      from "../components/SectionTitle"
import { TabsNav }           from "../components/TabsNav"
import { Widget }            from "../components/Widget"
import { WidgetTable }       from "../components/WidgetTable"
import { WidgetCohortTable } from "../components/WidgetCohortTable"
import {
  formatDateMonthAndYear,
  formatToPercentageWithDecimal,
  generateRequestContextHeaders,
  Utils,
  PagePaths,
  formatValueInEuros
} from "../Utils"
import { ARPUChart, AverageDiscountChart, BillingCycleDistributionChart, ChurnChart, CreditChart, CustomersCountChart, GrowthChart, MRRChart, NewCustomersCountChart, PatientsLimitDistributionChart, PricingPlanDistributionChart, RefundsChart, RevenueChart } from "./saas/SaasCharts"

const SAAS_MARKET_IDS = {
  WORLDWIDE:   0,
  PORTUGAL:    3,
  SPAIN:       2,
  FRANCE:      1,
  UK:          5,
  ITALY:       4,
  GERMANY:     8,
  BELGIUM:     13,
  SWITZERLAND: 14,
  LUXEMBOURG:  16,
  IRELAND:     15,
  USA:         6,
  CANADA:      12,
  BRAZIL:      7,
  MEXICO:      10,
  ARGENTINA:   11,
  AUSTRALIA:   9,
}

const SAAS_MARKETS = {
  WORLDWIDE:   { name: "🌍", code: "worldwide"      },
  FRANCE:      { name: "🇫🇷", code: "france"         },
  SPAIN:       { name: "🇪🇸", code: "spain"          },
  PORTUGAL:    { name: "🇵🇹", code: "portugal"       },
  ITALY:       { name: "🇮🇹", code: "italy"          },
  UK:          { name: "🇬🇧", code: "united-kingdom" },
  USA:         { name: "🇺🇸", code: "united-states"  },
  BRAZIL:      { name: "🇧🇷", code: "brazil"         },
  GERMANY:     { name: "🇩🇪", code: "germany"        },
  AUSTRALIA:   { name: "🇦🇺", code: "australia"      },
  MEXICO:      { name: "🇲🇽", code: "mexico"         },
  ARGENTINA:   { name: "🇦🇷", code: "argentina"      },
  CANADA:      { name: "🇨🇦", code: "canada"         },
  BELGIUM:     { name: "🇧🇪", code: "belgium"        },
  SWITZERLAND: { name: "🇨🇭", code: "switzerland"    },
  IRELAND:     { name: "🇮🇪", code: "ireland"        },
  LUXEMBOURG:  { name: "🇱🇺", code: "luxembourg"     },
}

const generateCohortReportQuery = (cohortReportName) => {
  return `
    ${cohortReportName} {
      cohortRows {
        monthIso
        monthFormatted
        baseValue

        cohortValues {
          monthIso
          value
          percentage
          percentageFormatted
        }
      }
    }
  `
}

const QUERY = gql`
  query($saasMarketId: Int!) {
    saasReport {
      updatedAt

      market(saasMarketId: $saasMarketId) {
        metricsPerMonth {
          monthFormatted
          monthIso

          newCustomersCount
          newFirstTimeCustomersCount
          newChurnRecoveredCustomersCount
          customersCount
          lostCustomersCount
          grossMrrInEuros
          netMrrInEuros
          grossArpuInEuros
          netArpuInEuros
          revenueInEuros
          averageDiscountPercentage
          refundsInEuros
          creditInEuros

          customersBillingCycleMonthlyCount
          customersBillingCycleQuarterlyCount
          customersBillingCycleSemiannuallyCount
          customersBillingCycleAnnuallyCount

          customersPricingPlanMealPlansCount
          customersPricingPlanFollowUpCount

          customersPatientsLimitTenCount
          customersPatientsLimitTwentyFiveCount
          customersPatientsLimitSeventyFiveCount
          customersPatientsLimitUnlimitedCount
        }

        ${generateCohortReportQuery("firstSubscriptionsGrossMrrCohortReport")}
        ${generateCohortReportQuery("firstSubscriptionsNetMrrCohortReport")}
        ${generateCohortReportQuery("firstSubscriptionsProfessionalsCountCohortReport")}
      }
    }
  }
`

const CURRENT_PROFESSIONAL_CUSTOMERS_QUERY = gql`
  query($saasMarketId: Int!) {
    saasReport {
      market(saasMarketId: $saasMarketId) {
        currentProfessionalsCustomers {
          id
          name
          avatarUrl

          account {
            email
          }

          countryOfCustomer {
            name
            emoji
          }
        }
      }
    }
  }
`

function calculateRevenueByYear(saasReport, lastMonthMetrics) {
  const currentYear          = lastMonthMetrics.monthIso.slice(0,4)
  const currentCalendarMonth = lastMonthMetrics.monthIso.slice(5,7)
  const lastYear             = (parseInt(currentYear) - 1).toString()

  let yearToDateRevenueInEuros     = 0
  let lastYearToDateRevenueInEuros = 0

  saasReport.market.metricsPerMonth.filter(metrics => {
    const year          = metrics.monthIso.slice(0,4)
    const calendarMonth = metrics.monthIso.slice(5,7)

    if (calendarMonth <= currentCalendarMonth) {
      if (year == currentYear) {
        yearToDateRevenueInEuros += metrics.revenueInEuros
      } else if (year == lastYear) {
        lastYearToDateRevenueInEuros += metrics.revenueInEuros
      }
    }
  })

  return [ yearToDateRevenueInEuros, yearToDateRevenueInEuros / lastYearToDateRevenueInEuros ]
}

function generateTabsData(saasMarketId) {
  return Object.keys(SAAS_MARKET_IDS).map(code => {
    const market = SAAS_MARKETS[code]
    const href   = `${PagePaths.saas.home}/${market.code}`

    return { name: market.name, href, current: saasMarketId === SAAS_MARKET_IDS[code], code }
  })
}

const PROFESSIONALS_CUSTOMERS_COLUMNS = [
  "Name", "Email", "Country"
]

function CurrentProfessionalsCustomers({ userData, saasMarketId }) {
  const [showData, setShowData] = useState(false)

  if (showData) {
    return <CurrentProfessionalsCustomersListing userData={userData} saasMarketId={saasMarketId} />
  } else {
    return (
      <Widget title="Current customers" widgetBodyClass="text-center p-6">
        <Button color="indigo" onClick={() => setShowData(true)}>
          <UsersIcon className="-ml-1 mr-2 h-5 w-5" />
          Load the current customers list for this market
        </Button>
      </Widget>
    )
  }
}

function CurrentProfessionalsCustomersListing({ userData, saasMarketId }) {
  const requestContext = generateRequestContextHeaders(userData)
  requestContext.variables = { saasMarketId }

  const { loading, error, data } = useQuery(CURRENT_PROFESSIONAL_CUSTOMERS_QUERY, requestContext)

  if (loading) return <p>⚙️ Loading...</p>
  if (error)   return <ApiRequestError error={error} />

  const { saasReport } = data

  return (
    <WidgetTable title="Current customers" columns={PROFESSIONALS_CUSTOMERS_COLUMNS}>
      {sortBy(saasReport.market.currentProfessionalsCustomers, professional => `${professional.countryOfCustomer.name}${professional.name.toLocaleUpperCase()}`).map(professional => (
        <WidgetTable.TableRow key={professional.id}>
          <WidgetTable.TableCellProfessional professional={professional} />
          <WidgetTable.TableCellEmail email={professional.account.email} />
          <WidgetTable.TableCellText text={`${professional.countryOfCustomer.emoji} ${professional.countryOfCustomer.name}`} />
        </WidgetTable.TableRow>
      ))}
    </WidgetTable>
  )
}

function Saas({userData}) {
  const params   = useParams()
  const marketId = SAAS_MARKET_IDS[ Object.keys(SAAS_MARKETS).find(market => SAAS_MARKETS[market].code == params.code) ]

  const [saasMarketId, setSaasMarketId] = useState(marketId)

  const requestContext     = generateRequestContextHeaders(userData)
  requestContext.variables = { saasMarketId }

  const { loading, error, data } = useQuery(QUERY, requestContext)

  if (loading) return <p>Loading...</p>
  if (error)   return <ApiRequestError error={error} />

  const { saasReport } = data

  const currentMonthMetrics     = saasReport.market.metricsPerMonth[ saasReport.market.metricsPerMonth.length - 1 ]
  const lastMonthMetrics        = saasReport.market.metricsPerMonth[ saasReport.market.metricsPerMonth.length - 2 ]
  const penultimateMonthMetrics = saasReport.market.metricsPerMonth[ saasReport.market.metricsPerMonth.length - 3 ]

  const customersCountVariation = lastMonthMetrics.customersCount / penultimateMonthMetrics.customersCount
  const grossMrrVariation       = lastMonthMetrics.grossMrrInEuros / penultimateMonthMetrics.grossMrrInEuros
  const netMrrVariation         = lastMonthMetrics.netMrrInEuros / penultimateMonthMetrics.netMrrInEuros

  const currentMonthCustomersCountVariation = currentMonthMetrics.customersCount / lastMonthMetrics.customersCount
  const currentMonthGrossMrrVariation       = currentMonthMetrics.grossMrrInEuros / lastMonthMetrics.grossMrrInEuros
  const currentMonthNetMrrVariation         = currentMonthMetrics.netMrrInEuros / lastMonthMetrics.netMrrInEuros

  const lastMonthChurn        = lastMonthMetrics.lostCustomersCount / penultimateMonthMetrics.customersCount
  const penultimateMonthChurn = penultimateMonthMetrics.lostCustomersCount / saasReport.market.metricsPerMonth[ saasReport.market.metricsPerMonth.length - 3 ].customersCount

  const [ yearToDateRevenueInEuros, yearToDateRevenueVariation ] = calculateRevenueByYear(saasReport, lastMonthMetrics)

  let churnVariation
  if (penultimateMonthChurn > 0) {
    churnVariation = lastMonthChurn / penultimateMonthChurn
  }

  const endOfChurn                     = moment(lastMonthMetrics.monthIso).add({month: 1, days: 7})
  const numberOfDaysOfChurnDataMissing = endOfChurn.diff(moment(saasReport.updatedAt), "days")

  return (
    <div>
      <Banner type="info" text={`The data shown here was last updated at ${formatDateMonthAndYear(saasReport.updatedAt)}`} />

      {lastMonthMetrics.monthIso == saasReport.updatedAt.slice(0,7) &&
        <Banner type="warning" text={"The data shown here is related to an ongoing month and thus all of these metrics will change until the end of the current month."} />
      }

      <TabsNav tabs={generateTabsData(saasMarketId)} onClick={tab => setSaasMarketId(SAAS_MARKET_IDS[tab.code])} />

      <SectionTitle title="Last Month" withMargin={true} />

      <div className="grid grid-cols-3 gap-5">
        <CardWithMetric
          name              ="Month"
          value             ={lastMonthMetrics.monthFormatted}
          valueFontSizeClass={"text-2xl"}
        />
        <CardWithMetric
          name              ="Customers"
          value             ={lastMonthMetrics.customersCount}
          valueFontSizeClass={"text-2xl"}
          valueVariation    ={customersCountVariation}
        />
        <CardWithMetric
          name                      ="Churn"
          value                     ={`${formatToPercentageWithDecimal(Math.abs(lastMonthChurn))} %`}
          valueFontSizeClass        ={"text-2xl"}
          valueVariation            ={!!churnVariation && churnVariation}
          valueVariationInvertColors={true}
        />

        <CardWithMetric
          name              ="MRR Gross"
          value             ={formatValueInEuros(lastMonthMetrics.grossMrrInEuros)}
          valueFontSizeClass={"text-2xl"}
          valueVariation    ={grossMrrVariation}
        />
        <CardWithMetric
          name              ="MRR Net"
          value             ={formatValueInEuros(lastMonthMetrics.netMrrInEuros)}
          valueFontSizeClass={"text-2xl"}
          valueVariation    ={netMrrVariation}
        />
        <CardWithMetric
          name              ="YTD Revenue (last year comparison)"
          value             ={formatValueInEuros(yearToDateRevenueInEuros)}
          valueFontSizeClass={"text-2xl"}
          valueVariation    ={yearToDateRevenueVariation}
        />
      </div>

      <SectionTitle title="Current Month" withMargin={true} />

      <div className="grid grid-cols-3 gap-5">
        <CardWithMetric
          name              ="Month"
          value             ={currentMonthMetrics.monthFormatted}
          valueFontSizeClass={"text-2xl"}
        />
        <CardWithMetric
          name              ="Customers"
          value             ={currentMonthMetrics.customersCount}
          valueFontSizeClass={"text-2xl"}
          valueVariation    ={currentMonthCustomersCountVariation}
        />
        <CardWithMetric
          name              ="New first time customers"
          value             ={currentMonthMetrics.newFirstTimeCustomersCount}
          valueFontSizeClass={"text-2xl"}
        />

        <CardWithMetric
          name              ="MRR Gross"
          value             ={formatValueInEuros(currentMonthMetrics.grossMrrInEuros)}
          valueFontSizeClass={"text-2xl"}
          valueVariation    ={currentMonthGrossMrrVariation}
        />
        <CardWithMetric
          name              ="MRR Net"
          value             ={formatValueInEuros(currentMonthMetrics.netMrrInEuros)}
          valueFontSizeClass={"text-2xl"}
          valueVariation    ={currentMonthNetMrrVariation}
        />
      </div>

      <div className="mt-8">
        <div className="mt-5 grid grid-cols-2 gap-5">
          <Widget title="Growth">
            <GrowthChart saasReport={saasReport} />
          </Widget>

          <Widget title="Total Customers">
            <CustomersCountChart saasReport={saasReport} />
          </Widget>

          <Widget title="New Customers">
            <NewCustomersCountChart saasReport={saasReport} />
          </Widget>

          <Widget
            title      ="Churn"
            bannerProps={numberOfDaysOfChurnDataMissing > 0 &&
              {
                type: "danger",
                text: `Final churn will be higher ( ${numberOfDaysOfChurnDataMissing} days of data missing )`,
                compact: true
              }
            }
          >
            <ChurnChart saasReport={saasReport} />
          </Widget>

          <Widget title="Monthly Recurring Revenue (MRR)">
            <MRRChart saasReport={saasReport} />
          </Widget>

          <Widget title="Average Revenue Per User (ARPU)">
            <ARPUChart saasReport={saasReport} />
          </Widget>

          <Widget title="Revenue">
            <RevenueChart saasReport={saasReport} />
          </Widget>
        </div>
      </div>

      <div className="mt-8">
        <WidgetCohortTable
          cohortReports      ={[saasReport.market.firstSubscriptionsGrossMrrCohortReport, saasReport.market.firstSubscriptionsNetMrrCohortReport, saasReport.market.firstSubscriptionsProfessionalsCountCohortReport]}
          cohortReportOptions={["🚀 MRR Gross", "💶 MRR Net", "👩‍⚕️ Customers"]}
          title              ="Cohort analysis by month of first subscription"
        />
      </div>

      <div className="mt-8">
        <div className="grid grid-cols-3 gap-5">
          <Widget title="Billing Cycle Distribution">
            <BillingCycleDistributionChart saasReport={saasReport} lastMonthMetrics={lastMonthMetrics} />
          </Widget>

          <Widget title="Pricing Plan Distribution">
            <PricingPlanDistributionChart saasReport={saasReport} lastMonthMetrics={lastMonthMetrics} />
          </Widget>

          <Widget title="Patients Limit Distribution">
            <PatientsLimitDistributionChart saasReport={saasReport} lastMonthMetrics={lastMonthMetrics} />
          </Widget>
        </div>
      </div>

      <div className="mt-8">
        <div className="mt-5 grid grid-cols-2 gap-5">
          <Widget title="Average Discount">
            <AverageDiscountChart saasReport={saasReport} />
          </Widget>

          <Widget title="Credit">
            <CreditChart saasReport={saasReport} />
          </Widget>

          <Widget title="Refunds">
            <RefundsChart saasReport={saasReport} />
          </Widget>
        </div>
      </div>

      {Utils.permissions.canSeeProfessionalsCustomersListing(userData.account.accountRoleId) &&
        <div className="mt-8">
          <CurrentProfessionalsCustomers userData={userData} saasMarketId={saasMarketId} />
        </div>
      }
    </div>
  )
}

export { Saas }
