import React from "react"

function SectionTitle({title, withMargin}) {
  const marginClass = withMargin ? "mt-8 mb-4" : ""

  return (
    <h3 className={`text-lg leading-6 font-medium text-gray-900 ${marginClass}`}>{title}</h3>
  )
}

export { SectionTitle }
