import ChartJS                  from "chart.js/auto"
import { Component, createRef } from "react"

class BarLineChart extends Component {
  chartRef = createRef()

  componentDidMount() {  
    const data = {
      labels:   this.props.labels,
      datasets: this.props.datasets.map(dataset => (
        {
          ...dataset,
          pointStyle:       "circle",
          pointRadius:      6,
          pointHoverRadius: 13,
          borderDash:       [5, 5],
        }
      ))
    }
  
    const config = {
      type: "bar",
      data: data,
      options: {
        scales: {
          y: {
            type:     "linear",
            display:  true,
            position: "left",
          },
          y1: {
            type:     "linear",
            display:  true,
            position: "right",
            grid: {
              drawOnChartArea: false,
            },
          },
        }
      }
    }

    new ChartJS(this.chartRef.current, config)
  }

  render() {
    return <canvas ref={this.chartRef} className="px-4 py-5" height="250px" />
  }
}

export { BarLineChart }
