import { Fragment }                            from "react"
import { Menu, Popover, Transition }           from "@headlessui/react"
import { DownloadIcon, PhoneIcon, SearchIcon, ChatIcon } from "@heroicons/react/solid"
import { CalendarIcon, HomeIcon, BriefcaseIcon, UserCircleIcon, ChartSquareBarIcon, BeakerIcon, DeviceMobileIcon, AcademicCapIcon, EmojiHappyIcon } from "@heroicons/react/outline"
import { NavLink, Routes, Route }              from "react-router-dom";

import classNames           from "classnames"
import { PagePaths, Utils } from "./Utils"

import { Badge }                    from "./components/Badge"
import { Home }                     from "./pages/Home"
import { Companies }                from "./pages/Companies"
import { Professionals }            from "./pages/Professionals"
import { PatientEngagements }       from "./pages/PatientEngagements"
import { Challenges }               from "./pages/Challenges"
import { SchedulingEvents }         from "./pages/SchedulingEvents"
import { Saas }                     from "./pages/Saas"
import { Brands }                   from "./pages/Brands"
import { Brand }                    from "./pages/Brand"
import { Recipes }                  from "./pages/Recipes"
import { Export }                   from "./pages/Export"
import { Translations }             from "./pages/Translations"

const userNavigation = [
  { name: 'Sign out', href: '#' },
]

function SidebarLinkGroup({name, children}) {
  return (
    <div className="pb-8 space-y-1 border-none">
      <h3 className="mb-4 px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider">{name}</h3>
      {children}
    </div>
  )
}

function SidebarLink({name, href, icon, activeCustomMatch}) {
  const item = { icon }
  // TODO
  const isActiveCondition = (isActive) => activeCustomMatch?.() || (isActive && (href == window.location.pathname))

  return (
    <NavLink
      key={name}
      to={href}
      className={({ isActive }) => classNames(
        isActiveCondition(isActive) ? 'bg-gray-200 text-gray-900' : 'text-gray-600 hover:bg-gray-50',
        'group flex items-center px-3 py-2 text-sm font-medium rounded-md'
      )}
    >
      { ({ isActive }) => (
        <>
          <item.icon
            className={classNames(
              isActiveCondition(isActive) ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
              'flex-shrink-0 -ml-1 mr-3 h-6 w-6'
            )}
          />
          <span className="truncate">{name}</span>
        </>
      )}
    </NavLink>
  )
}

export default function Layout({userData, setUserData}) {
  return (
    <>
      <div className="min-h-full">
        {/* When the mobile menu is open, add `overflow-hidden` to the `body` element to prevent double scrollbars */}
        <Popover
          as="header"
          className={({ open }) =>
            classNames(
              open ? 'fixed inset-0 z-40 overflow-y-auto' : '',
              'bg-white shadow-sm lg:static lg:overflow-y-visible'
            )
          }
        >
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="relative flex justify-between xl:grid xl:grid-cols-12 lg:gap-8">
                  <div className="flex md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-2">
                    <div className="flex-shrink-0 flex items-center">
                      <a href="#">
                        <img
                          className="block h-8 w-auto"
                          src="https://app.nutrium.com/images/logos/nutrium.png"
                          alt="Workflow"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="min-w-0 flex-1 md:px-8 lg:px-0 xl:col-span-6">
                    <div className="flex items-center px-6 py-4 md:max-w-3xl md:mx-auto lg:max-w-none lg:mx-0 xl:px-0">
                      <div className="w-full">
                        <label htmlFor="search" className="sr-only">
                          Search
                        </label>
                        <div className="relative">
                          <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </div>
                          <input
                            id="search"
                            name="search"
                            className="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-rose-500 focus:border-rose-500 sm:text-sm bg-gray-50"
                            placeholder="Search"
                            type="search"
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="hidden lg:flex lg:items-center lg:justify-end xl:col-span-4">
                    {/* Profile dropdown */}
                    <Menu as="div" className="flex-shrink-0 relative ml-5">
                      <div>
                        <Menu.Button className="bg-white rounded-full flex items-center">
                          <img className="mr-3 h-8 w-8 rounded-full" src={userData.account.professional.avatarUrl} />
                          <span className="text-base font-medium text-gray-500">{userData.account.professional.name}</span>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none">
                          {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <a
                                    href={item.href}
                                    className={classNames(
                                      active ? 'bg-gray-100' : '',
                                      'block py-2 px-4 text-sm text-gray-700'
                                    )}
                                    // #TODO
                                    onClick={() => {
                                      localStorage.removeItem("userData")
                                      setUserData(null)
                                    }}
                                  >
                                    {item.name}
                                  </a>
                                )}
                              </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>
            </>
          )}
        </Popover>

        <div className="py-10">
          <div className="max-w-3xl mx-auto sm:px-6 lg:max-w-[90rem] lg:px-8 lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="hidden lg:block lg:col-span-3 xl:col-span-2">
              <nav aria-label="Sidebar" className="sticky top-4 divide-y divide-gray-300">
                <SidebarLinkGroup name="B2B">
                  <SidebarLink name='Home' href={PagePaths.b2b.home} icon={HomeIcon} activeCustomMatch={() => window.location.pathname == "/"} />
                  <SidebarLink name='Companies' href={PagePaths.b2b.companies} icon={BriefcaseIcon} />

                  {Utils.permissions.canSeeNutritionServiceRequestsListing(userData.account.accountRoleId) &&
                    <SidebarLink name='Requests' href={PagePaths.b2b.requests} icon={CalendarIcon} />
                  }
                  {Utils.permissions.canSeeNutritionServiceRequestsListing(userData.account.accountRoleId) &&
                    <SidebarLink name='Patients' href={PagePaths.b2b.patients} icon={UserCircleIcon} />
                  }
                  <SidebarLink name='Professionals' href={PagePaths.b2b.professionals} icon={UserCircleIcon} />
                  <SidebarLink name='Challenges' href={PagePaths.b2b.challenges} icon={EmojiHappyIcon} />
                </SidebarLinkGroup>

                <SidebarLinkGroup name="SaaS">
                  <SidebarLink name='Metrics' href={`${PagePaths.saas.home}/worldwide`} icon={ChartSquareBarIcon} activeCustomMatch={() => window.location.pathname.match(PagePaths.saas.home)} />
                </SidebarLinkGroup>

                <SidebarLinkGroup name="Foods">
                  <SidebarLink name='Brands' href={PagePaths.brands.home} icon={BeakerIcon} activeCustomMatch={() => window.location.pathname.match(PagePaths.brands.home)}/>
                  <SidebarLink name='Recipes' href={PagePaths.recipes.home} icon={AcademicCapIcon} />
                </SidebarLinkGroup>

                <SidebarLinkGroup name="Misc">
                  {Utils.permissions.canSeeExportPage(userData.account.accountRoleId) &&
                    <SidebarLink name='Export' href={PagePaths.misc.export} icon={DownloadIcon} />
                  }
                  {Utils.inDevelopmentEnvironment() &&
                    <SidebarLink name='Translations' href={PagePaths.translations} icon={ChatIcon} />
                  }
                </SidebarLinkGroup>
              </nav>
            </div>

            <main className="lg:col-span-9 xl:col-span-10">
              <Routes>
                <Route path={PagePaths.home}                       element={ <Home userData={userData}          /> } />
                <Route path={PagePaths.b2b.home}                   element={ <Home userData={userData}          /> } />
                <Route path={PagePaths.b2b.companies}              element={ <Companies userData={userData}     /> } />
                <Route path={PagePaths.b2b.professionals}          element={ <Professionals userData={userData} /> } />
                <Route path={PagePaths.b2b.challenges}             element={ <Challenges userData={userData}    /> } />
                <Route path={`${PagePaths.saas.home}/:code`}       element={ <Saas userData={userData}          /> } />
                <Route path={PagePaths.brands.home}                element={ <Brands userData={userData}        /> } />
                <Route path={`${PagePaths.brands.home}/:id/:code`} element={ <Brand userData={userData}         /> } />
                <Route path={PagePaths.recipes.home}               element={ <Recipes userData={userData}       /> } />
                <Route path={PagePaths.translations}               element={ <Translations userData={userData}  /> } />
                {Utils.permissions.canSeeExportPage(userData.account.accountRoleId) &&
                  <Route path={PagePaths.misc.export} element={ <Export userData={userData} /> } />
                }
                {Utils.permissions.canSeeNutritionServiceRequestsListing(userData.account.accountRoleId) &&
                  <Route path={PagePaths.b2b.requests} element={ <SchedulingEvents userData={userData} /> } />
                }
                {Utils.permissions.canSeeNutritionServiceRequestsListing(userData.account.accountRoleId) &&
                  <Route path={PagePaths.b2b.patients} element={ <PatientEngagements userData={userData} /> } />
                }
              </Routes>
            </main>
          </div>
        </div>
      </div>
    </>
  )
}
