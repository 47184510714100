
import { BarLineChart }                  from "../../components/charts/BarLineChart"
import { Chart }                         from "../../components/charts/Chart"
import { PieChart }                      from "../../components/charts/PieChart"
import { formatToPercentageWithDecimal } from "../../Utils"

const CHART_COLORS = {
  green: {
    light:  "#DCFCE7",
    medium: "#BBF7D0",
    dark:   "#22C55E",
  },
  blue: {
    light:  "#A5F3FC",
    dark:   "#06B6D4",
  },
  orange: {
    light:  "#FED7AA",
    dark:   "#F97316",
  },
  purple: {
    light:  "#C7D2FE",
    dark:   "#6366F1",
  },
  red: {
    light:  "#FCA5A5",
    dark:   "#EF4444",
  },
}

function GrowthChart({saasReport}) {
  const { market } = saasReport

  const labels   = market.metricsPerMonth.map(metric => metric.monthFormatted)
  const datasets = [
    {
      label:           'Total customers (%)',
      backgroundColor: CHART_COLORS.green.medium,
      borderColor:     CHART_COLORS.green.dark,
      data:            [null].concat(market.metricsPerMonth.slice(1).map((metric, index) => formatToPercentageWithDecimal((metric.customersCount / market.metricsPerMonth[index].customersCount).toFixed(3) - 1))),
    },
    {
      label:           'MRR Gross (%)',
      backgroundColor: CHART_COLORS.blue.light,
      borderColor:     CHART_COLORS.blue.dark,
      data:            [null].concat(market.metricsPerMonth.slice(1).map((metric, index) => formatToPercentageWithDecimal((metric.grossMrrInEuros / market.metricsPerMonth[index].grossMrrInEuros).toFixed(3) - 1))),
    },
    {
      label:           'MRR Net (%)',
      backgroundColor: CHART_COLORS.purple.light,
      borderColor:     CHART_COLORS.purple.dark,
      data:            [null].concat(market.metricsPerMonth.slice(1).map((metric, index) => formatToPercentageWithDecimal((metric.netMrrInEuros / market.metricsPerMonth[index].netMrrInEuros).toFixed(3) - 1))),
    },
  ]

  return <Chart type="bar" datasets={datasets} labels={labels} />
}

function NewCustomersCountChart({saasReport}) {
  const {market} = saasReport

  const labels = market.metricsPerMonth.map(metric => metric.monthFormatted)
  const datasets = [
    {
      label:           'New Customers',
      backgroundColor: CHART_COLORS.green.light,
      borderColor:     CHART_COLORS.green.dark,
      data:            market.metricsPerMonth.map(metric => metric.newCustomersCount),
    },
    {
      label:           'New First Time Customers',
      backgroundColor: CHART_COLORS.blue.light,
      borderColor:     CHART_COLORS.blue.dark,
      data:            market.metricsPerMonth.map(metric => metric.newFirstTimeCustomersCount),
    },
    {
      label:           'New Churn Recovered Customers',
      backgroundColor: CHART_COLORS.purple.light,
      borderColor:     CHART_COLORS.purple.dark,
      data:            market.metricsPerMonth.map(metric => metric.newChurnRecoveredCustomersCount),
    },
  ]

  return <Chart datasets={datasets} labels={labels} />
}

function CustomersCountChart({saasReport}) {
  const { market } = saasReport

  const labels   = market.metricsPerMonth.map(metric => metric.monthFormatted)
  const datasets = [
    {
      label:           'Total Customers',
      backgroundColor: CHART_COLORS.green.light,
      borderColor:     CHART_COLORS.green.dark,
      data:            market.metricsPerMonth.map(metric => metric.customersCount),
    }
  ]

  return <Chart datasets={datasets} labels={labels} />
}

function ChurnChart({saasReport}) {
  const { market } = saasReport

  const labels   = market.metricsPerMonth.map(metric => metric.monthFormatted)
  const datasets = [
    {
      label:           'Churn (#)',
      backgroundColor: CHART_COLORS.orange.light,
      borderColor:     CHART_COLORS.orange.dark,
      data:            market.metricsPerMonth.map(metric => metric.lostCustomersCount),
      yAxisID:         'y',
      order:           2,
    },
    {
      label:           'Churn Rate (%)',
      backgroundColor: CHART_COLORS.red.light,
      borderColor:     CHART_COLORS.red.dark,
      data:            [null].concat(market.metricsPerMonth.slice(1).map((metric, index) => formatToPercentageWithDecimal((metric.lostCustomersCount / market.metricsPerMonth[index].customersCount).toFixed(3)))),
      type:            "line",
      yAxisID:         'y1',
      order:           1,
    },
  ]

  return <BarLineChart datasets={datasets} labels={labels} />
}

function MRRChart({saasReport}) {
  const { market } = saasReport

  const labels   = market.metricsPerMonth.map(metric => metric.monthFormatted)
  const datasets = [
    {
      label:           'MRR Gross (€)',
      backgroundColor: CHART_COLORS.green.light,
      borderColor:     CHART_COLORS.green.dark,
      data:            market.metricsPerMonth.map(metric => metric.grossMrrInEuros),
    },
    {
      label:           'MRR Net (€)',
      backgroundColor: CHART_COLORS.blue.light,
      borderColor:     CHART_COLORS.blue.dark,      
      data:            market.metricsPerMonth.map(metric => metric.netMrrInEuros),
    },
  ]

  return <Chart datasets={datasets} labels={labels} />
}

function ARPUChart({saasReport}) {
  const { market } = saasReport

  const labels   = market.metricsPerMonth.map(metric => metric.monthFormatted)
  const datasets = [
    {
      label:           'ARPU Gross (€)',
      backgroundColor: CHART_COLORS.green.light,
      borderColor:     CHART_COLORS.green.dark,
      data:            market.metricsPerMonth.map(metric => metric.grossArpuInEuros),
    },
    {
      label:           'ARPU Net (€)',
      backgroundColor: CHART_COLORS.blue.light,
      borderColor:     CHART_COLORS.blue.dark,
      data:            market.metricsPerMonth.map(metric => metric.netArpuInEuros),
    },
  ]

  return <Chart datasets={datasets} labels={labels} />
}

function RevenueChart({saasReport}) {
  const { market } = saasReport

  const labels   = market.metricsPerMonth.map(metric => metric.monthFormatted)
  const datasets = [
    {
      label:           'Revenue (€)',
      backgroundColor: CHART_COLORS.green.light,
      borderColor:     CHART_COLORS.green.dark,
      data:            market.metricsPerMonth.map(metric => metric.revenueInEuros),
    },
  ]

  return <Chart datasets={datasets} labels={labels} />
}

function AverageDiscountChart({saasReport}) {
  const { market } = saasReport

  const labels   = market.metricsPerMonth.map(metric => metric.monthFormatted)
  const datasets = [
    {
      label:           'Average Discount (%)',
      backgroundColor: CHART_COLORS.orange.light,
      borderColor:     CHART_COLORS.orange.dark,
      data:            market.metricsPerMonth.map(metric => metric.averageDiscountPercentage),
    },
  ]

  return <Chart datasets={datasets} labels={labels} />
}

function CreditChart({saasReport}) {
  const { market } = saasReport

  const labels   = market.metricsPerMonth.map(metric => metric.monthFormatted)
  const datasets = [
    {
      label:           'Credit (€)',
      backgroundColor: CHART_COLORS.purple.light,
      borderColor:     CHART_COLORS.purple.dark,
      data:            market.metricsPerMonth.map(metric => metric.creditInEuros),
    },
  ]

  return <Chart datasets={datasets} labels={labels} />
}

function RefundsChart({saasReport}) {
  const { market } = saasReport

  const labels   = market.metricsPerMonth.map(metric => metric.monthFormatted)
  const datasets = [
    {
      label:           'Refunds (€)',
      backgroundColor: CHART_COLORS.red.light,
      borderColor:     CHART_COLORS.red.dark,
      data:            market.metricsPerMonth.map(metric => metric.refundsInEuros),
    }
  ]

  return <Chart datasets={datasets} labels={labels} />
}

function PricingPlanDistributionChart({saasReport, lastMonthMetrics}) {
  const {market} = saasReport

  const labels   = ["Meal Plans", "Follow Up"]
  const datasets = [
    {
      data: [
        (lastMonthMetrics.customersPricingPlanMealPlansCount / lastMonthMetrics.customersCount),
        (lastMonthMetrics.customersPricingPlanFollowUpCount / lastMonthMetrics.customersCount),
      ],
      backgroundColor: [CHART_COLORS.green.dark, CHART_COLORS.blue.dark, CHART_COLORS.red.dark, CHART_COLORS.orange.dark]
    },
  ]

  return <PieChart datasets={datasets} labels={labels} />
}

function BillingCycleDistributionChart({saasReport, lastMonthMetrics}) {
  const {market} = saasReport

  const labels   = ["Monthly", "Quarterly", "Semiannual", "Annual"]
  const datasets = [
    {
      data: [
        (lastMonthMetrics.customersBillingCycleMonthlyCount      / lastMonthMetrics.customersCount),
        (lastMonthMetrics.customersBillingCycleQuarterlyCount    / lastMonthMetrics.customersCount),
        (lastMonthMetrics.customersBillingCycleSemiannuallyCount / lastMonthMetrics.customersCount),
        (lastMonthMetrics.customersBillingCycleAnnuallyCount     / lastMonthMetrics.customersCount),
      ],
      backgroundColor: [CHART_COLORS.green.dark, CHART_COLORS.blue.dark, CHART_COLORS.red.dark, CHART_COLORS.orange.dark]
    },
  ]

  return <PieChart datasets={datasets} labels={labels} />
}

function PatientsLimitDistributionChart({saasReport, lastMonthMetrics}) {
  const {market} = saasReport

  const labels   = ["10", "25", "75", "Unlimited"]
  const datasets = [
    {
      data: [
        (lastMonthMetrics.customersPatientsLimitTenCount         / lastMonthMetrics.customersCount),
        (lastMonthMetrics.customersPatientsLimitTwentyFiveCount  / lastMonthMetrics.customersCount),
        (lastMonthMetrics.customersPatientsLimitSeventyFiveCount / lastMonthMetrics.customersCount),
        (lastMonthMetrics.customersPatientsLimitUnlimitedCount   / lastMonthMetrics.customersCount),
      ],
      backgroundColor: [CHART_COLORS.green.dark, CHART_COLORS.blue.dark, CHART_COLORS.red.dark, CHART_COLORS.orange.dark]
    },
  ]

  return <PieChart datasets={datasets} labels={labels} />
}

export {
  GrowthChart,
  NewCustomersCountChart,
  CustomersCountChart,
  ChurnChart,
  MRRChart,
  ARPUChart,
  RevenueChart,
  RefundsChart,
  CreditChart,
  AverageDiscountChart,
  PricingPlanDistributionChart,
  PatientsLimitDistributionChart,
  BillingCycleDistributionChart
}
