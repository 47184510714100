import classNames from "classnames"
import React      from "react"

import { formatDateMonthAndYear, getFirstAndLastName, Utils } from "../Utils"
import { WidgetTable } from "./WidgetTable"

const COLUMNS = [
  "id",
  "gender",
  "birthdate",
  "days In MobileApp",
  "first Day In Mobile App",
  "messages Sent",
  "emails Sent",
  "individual Messages Received",
  "total ScheduledA ppointments",
  "total Completed Appointments",
  "first Appointment Date",
  "next Appointment Date"
]

function PatientEngagementsWidget({ title, patientEngagements, userData}) {
  return (
    <WidgetTable title={title} columns={COLUMNS}>
      {patientEngagements.map(patientEngagement => (
        <WidgetTable.TableRow key={patientEngagement.id}>

          <WidgetTable.TableCellText text={patientEngagement.id} />
          <WidgetTable.TableCellText text={patientEngagement.gender} />
          <WidgetTable.TableCellText text={formatDateMonthAndYear(patientEngagement.birthdate)} />
          <WidgetTable.TableCellText text={patientEngagement.daysInMobileApp} />
          <WidgetTable.TableCellText text={formatDateMonthAndYear(patientEngagement.firstDayInMobileApp)} />
          <WidgetTable.TableCellText text={patientEngagement.messagesSent} />
          <WidgetTable.TableCellText text={patientEngagement.emailsSent} />
          <WidgetTable.TableCellText text={patientEngagement.individualMessagesReceived} />
          <WidgetTable.TableCellText text={patientEngagement.totalScheduledAppointments} />
          <WidgetTable.TableCellText text={patientEngagement.totalCompletedAppointments} />
          <WidgetTable.TableCellText text={formatDateMonthAndYear(patientEngagement.firstAppointmentDate)} />
          <WidgetTable.TableCellText text={formatDateMonthAndYear(patientEngagement.nextAppointmentDate)} />

        </WidgetTable.TableRow>
      ))}
    </WidgetTable>
  )
}

export { PatientEngagementsWidget }
