import React, { useState } from "react"
import { useQuery } from "@apollo/client"
import { Select }   from "../components/Select"

import { ApiRequestError }                                        from "../components/ApiRequestError"
import { PatientEngagementsWidget }                                 from "../components/PatientEngagementsWidget"
import { generateRequestContextHeaders, PATIENT_ENGAGEMENTS_QUERY } from "../Utils"

function PatientEngagements({userData}) {
  const [companyId, setCompanyId] = useState(-1)
  const [companies, setCompanies] = useState([])

  const requestContext = generateRequestContextHeaders(userData)

  requestContext.variables = { companyId: -1 }

  if (companyId) { requestContext.variables.companyId= companyId }

  const { loading, error, data } = useQuery(PATIENT_ENGAGEMENTS_QUERY, requestContext)

  if (loading) return <p>Loading...</p>
  if (error)   return <ApiRequestError error={error} />

  if (companies.length === 0) {
    setCompanies(data.b2bReport.companies)
  }

  function companyOptions() {
    var result = [{name: "No company selected", value: -1}]

    companies.forEach((company) => {
      result.push({name: company.name, value: company.id})
    })

    return result
  }

  function selectCompany(value) {
    let valueAsNumber = parseInt(value);

    if(isNaN(valueAsNumber)) {
      setCompanyId(null);
    } else {
      setCompanyId(valueAsNumber);
    }
  }

  return (
    <>
      <div className="flex mb-[12px] space-x-4">
        <Select
          onChange={event => selectCompany(event.target.value)}
          options ={companyOptions()}
          value   ={companyId}
        />
      </div>
      <PatientEngagementsWidget
        patientEngagements={data.b2bReport.patientEngagements}
        title                   ="Patients"
        userData                ={userData}
      />
    </>
  )
}

export { PatientEngagements }
