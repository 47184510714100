import { ArrowSmDownIcon, ArrowSmUpIcon, MailIcon, PhoneIcon } from "@heroicons/react/solid"
import classNames from "classnames"
import { formatToPercentage, generateProfessionalUrl, getFirstAndLastName, INFINITE_VARIATION_VALUE } from "../Utils"
import { Paginator } from "./Paginator"

function WidgetTable({ title, columns, children, columnsLogos, footnote, titleComponent, paginatorProps }) {
  const hasColumnsHeaders = !!(columns?.length)

  return (
    <div>
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        {titleComponent && titleComponent }
        {title && <WidgetTableTitle title={title} /> }

        <div className="flex flex-col border-t border-gray-200">
          <div className="-mt-2 overflow-x-auto">
            <div className="pt-2 align-middle inline-block min-w-full">
              <div className="overflow-hidden border-gray-200">
                <table className="min-w-full divide-y divide-gray-200">
                  {hasColumnsHeaders &&
                    <thead className="bg-gray-50">
                      <tr>
                        {columns.map((column, index) =>
                          <th
                            key={column}
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            style={{maxWidth: "1500px"}}
                          >
                            <div className='flex items-center'>
                              {columnsLogos && columnsLogos[index] && <img className="mr-2 w-5 rounded-full object-cover" src={columnsLogos[index]} />}
                              {column}
                            </div>
                        </th>
                        )}
                      </tr>
                    </thead>
                  }
                  <tbody className="bg-white divide-y divide-gray-200">
                    {children}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {paginatorProps && <Paginator {...paginatorProps} /> }
      </div>

      {!!(footnote?.length) &&
        <p className="mt-2 text-sm text-gray-500">
          {footnote}
        </p>
      }
    </div>
  )
}

function WidgetTableTitle({ title }) {
  return (
    <div className="px-4 py-5 sm:px-6">
      <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
    </div>
  )
}

WidgetTable.TableRow = ({ children }) => {
  return (
    <tr className='hover:bg-gray-50'>
      {children}
    </tr>
  )
}

WidgetTable.TableCell = ({ children, compact, backgroundColor }) => {
  const style = { maxWidth: "1500px" }

  if (backgroundColor) { style.backgroundColor = backgroundColor }

  return (
    <td className={`${compact ? "text-center" : "px-6 py-4"} whitespace-nowrap`} style={style}>
      {children}
    </td>
  )
}

WidgetTable.TableCellText = ({ text, compact, backgroundColor, textColor, bold, onClickAction, styleOverride }) => {
  return (
    <WidgetTable.TableCell compact={compact} backgroundColor={backgroundColor} >
      <div onClick={onClickAction} className={`${compact ? "text-xs" : "text-sm"}  ${textColor || "text-gray-900"} ${bold ? "font-bold" : ""} truncate ${styleOverride}`}>{text}</div>
    </WidgetTable.TableCell>
  )
}

WidgetTable.TableCellEmail = ({ email }) => {
  return (
    <WidgetTable.TableCell>
      <div className="flex items-center">
        <MailIcon className="h-5 w-5 text-gray-500 mr-1.5" />
        <a href={`mailto:${email}`} className="text-sm text-gray-500 hover:bg-gray-50">{email}</a>
      </div>
    </WidgetTable.TableCell>
  )
}

WidgetTable.TableCellPhoneNumber = ({ phoneNumber }) => {
  return (
    <WidgetTable.TableCell>
      <div className="flex items-center">
        <PhoneIcon className="h-5 w-5 text-gray-500 mr-1.5" />
        <a href={`tel:${phoneNumber}`} className="text-sm text-gray-500 hover:bg-gray-50">{phoneNumber}</a>
      </div>
    </WidgetTable.TableCell>
  )
}

WidgetTable.TableCellVariaton = ({ text, value, currentMonth, neutralEveryBadgeColor }) => {
  return (
    <WidgetTable.TableCell>
      <div className="flex items-center">
        <span className="mr-3">{text}</span>

        {typeof value === "number" &&
          <div
            className={classNames(
              currentMonth ? 'bg-sky-100 text-sky-800' : neutralEveryBadgeColor ? "bg-neutral-100 text-neutral-800" : value >= 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
              'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
            )}
          >

            {value >= 0 ? (
              <ArrowSmUpIcon className={classNames("-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5", value == INFINITE_VARIATION_VALUE ? neutralEveryBadgeColor ? "text-neutral-800" : "text-green-500" : currentMonth ? "text-sky-500" : neutralEveryBadgeColor ? "text-neutral-800" : "text-green-500")} />
            ) : (
              <ArrowSmDownIcon className={classNames("-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5", currentMonth ? "text-sky-500" : neutralEveryBadgeColor ? "text-neutral-800": "text-red-500")} />
            )}

            {value == INFINITE_VARIATION_VALUE
              ? "🚀"
              : `${formatToPercentage(Math.abs(value))} %${currentMonth ? " *" : ""}`
            }
          </div>
        }
      </div>
    </WidgetTable.TableCell>
  )
}

WidgetTable.TableCellProfessional = ({ professional, avatarSize }) => {
  const newAvatarSize = avatarSize || 6

  return (
    <WidgetTable.TableCell>
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <img className={`h-${newAvatarSize} w-${newAvatarSize} rounded-full object-cover`} src={professional.avatarUrl} />
        </div>
        <div className="ml-4">
          <a href={generateProfessionalUrl(professional)} className="text-sm font-medium text-gray-900 hover:underline" target="_blank">{getFirstAndLastName(professional.name)}</a>
        </div>
      </div>
    </WidgetTable.TableCell>
  )
}

WidgetTable.TableHeaderCell = ({ text, colSpan }) => {
  return (
    <th
      scope="col"
      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
      style={{maxWidth: "1500px"}}
      colSpan={colSpan || 1}
    >
      {text}
    </th>
  )
}

export { WidgetTable }
