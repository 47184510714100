import React from "react"

const COLORS = {
  white:  "bg-white",
  sky:    "bg-sky-50 text-sky-700 border border-sky-200",
  orange: "bg-orange-50 text-orange-700 border border-orange-200",
}

function CardVerbatimText({text, color = "white"}) {
  return (
    <div className={`p-4 shadow rounded-lg break-all ${COLORS[color]}`}>
      <pre className="text-xs font-medium break-all whitespace-pre-wrap">{text}</pre>
    </div>
  )
}

export { CardVerbatimText }
