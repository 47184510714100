function Checkbox({checked, onChange}) {
  return (
    <input
      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
      onChange ={onChange}
      type     ="checkbox"
      checked  ={checked}
    />
  )
}

export { Checkbox }
