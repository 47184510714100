import React, { useState } from "react"
import { formatValueInEuros } from "../Utils"

import { Select }      from "./Select"
import { WidgetTable } from "./WidgetTable"

const LOCAL_STORAGE_KEYS = {
  cohortReportIndex: "saas.metrics.cohort.reportIndex",
  firstMonth:        "saas.metrics.cohort.firstMonth",
  showValueType:     "saas.metrics.cohort.showValueType",
}

const SHOW_VALUE_TYPE_OPTIONS = [
  { value: 0, name: "Percentages" },
  { value: 1, name: "Values"      },
]

function onCohortReportIndexChange(event, setValue) {
  const value = +event.target.value

  setValue(value)
  window.localStorage.setItem(LOCAL_STORAGE_KEYS.cohortReportIndex, value)
}

function onFirstMonthChange(event, setValue) {
  const { value } = event.target

  setValue(value)
  window.localStorage.setItem(LOCAL_STORAGE_KEYS.firstMonth, value)
}

function onShowValueTypeChange(setValue, currentValue) {
  const value = 1 - currentValue

  setValue(value)
  window.localStorage.setItem(LOCAL_STORAGE_KEYS.showValueType, value)
}

function WidgetCohortTable({cohortReports, cohortReportOptions, title}) {
  const [cohortReportIndex, setCohortReportIndex] = useState(+(window.localStorage.getItem(LOCAL_STORAGE_KEYS.cohortReportIndex) || 0))
  const cohortReport                              = cohortReports[cohortReportIndex]
  const [firstMonth, setFirstMonth]               = useState(window.localStorage.getItem(LOCAL_STORAGE_KEYS.firstMonth) || cohortReport.cohortRows[0].monthIso)
  const [showValueType, setShowValueType]         = useState(+(window.localStorage.getItem(LOCAL_STORAGE_KEYS.showValueType) || 0))

  const cohortRows = cohortReport.cohortRows.filter(cohortRow => cohortRow.monthIso >= firstMonth)

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg" id='cohort-analysis'>
      <div className="px-4 py-5 sm:px-6 flex items-center justify-center">
        <h3 className="text-lg leading-6 font-medium text-gray-900 min-w-[150px] grow">{title}</h3>
        <div className="flex items-center gap-4">
          <div>
            <Select
              onChange={event => onCohortReportIndexChange(event, setCohortReportIndex)}
              options ={cohortReportOptions.map((name, value) => ({ name, value }))}
              value   ={cohortReportIndex}
            />
          </div>

          <div>
            <Select
              onChange={event => onFirstMonthChange(event, setFirstMonth)}
              options ={cohortReport.cohortRows.map(cohortRow => ({name: "Since " + cohortRow.monthFormatted, value: cohortRow.monthIso}))}
              value   ={firstMonth}
            />
          </div>

          <div>
            <Select
              onChange={() => onShowValueTypeChange(setShowValueType, showValueType)}
              options ={SHOW_VALUE_TYPE_OPTIONS}
              value   ={showValueType}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col border-t border-gray-200 px-4 py-5 sm:p-0">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <WidgetTable.TableHeaderCell text="" />
                    <WidgetTable.TableHeaderCell text="" />
                    <WidgetTable.TableHeaderCell text="Months after the first subscription" colSpan={cohortRows.length} />
                  </tr>
                  <tr>
                    <WidgetTable.TableHeaderCell text="Month" />
                    <WidgetTable.TableHeaderCell text="Cohort Value" />

                    {cohortRows.map((_, index) => <WidgetTable.TableHeaderCell text={index} key={index} /> )}
                  </tr>
                </thead>

                <tbody className="bg-white divide-y divide-gray-200">
                  {cohortRows.map(cohortRow =>
                    <WidgetTable.TableRow key={cohortRow.monthIso}>
                      <WidgetTable.TableCellText text={cohortRow.monthFormatted} />
                      <WidgetTable.TableCellText text={formatValueInEuros(cohortRow.baseValue, true)} bold={true} />
                      {cohortRow.cohortValues.map((cohortValue, index) =>
                        <WidgetTable.TableCellText
                          text={showValueType ? formatValueInEuros(cohortValue.value, true) : cohortValue.percentageFormatted}
                          key={index}
                          compact={true}
                          backgroundColor={`rgb(52 211 153 / ${cohortValue.percentage})`}
                          textColor="text-emerald-800"
                        />
                      )}
                    </WidgetTable.TableRow>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { WidgetCohortTable }
