import classNames        from "classnames"
import sortBy            from "lodash/sortBy"
import { useQuery, gql } from "@apollo/client"

import { ApiRequestError }                                       from "../components/ApiRequestError"
import { formatDateMonthAndYear, generateRequestContextHeaders } from "../Utils"
import { WidgetTable }                                           from "../components/WidgetTable"

const ACTIVE_STATUS = "Active"

const QUERY = gql`
  query {
    b2bReport {
      companies {
        id
        name
        code
        logoUrl
        status
        createdAt
        patientsCount
        engagedPatientsInLast30DaysCount
        patientsWithoutProfessionalCount
        patientsOnMobileAppInLast30DaysCount

        country {
          name
          emoji
        }
      }
    }
  }
`

const COLUMNS = [
  "Name", "Country", "Patients", "Patients engaged last 30 days", "Patients on mobile app in the last 30 days",
  "Patients without professional", "Created at", "Status"
]

function sortCompanies(companies) {
  return sortBy(
    companies,
    company => `${company.status == ACTIVE_STATUS ? 1 : 0}${String(company.patientsCount).padStart(9, '0')}`
  ).reverse()
}

function Companies({userData}) {
  const { loading, error, data } = useQuery(QUERY, generateRequestContextHeaders(userData))

  if (loading) return <p>Loading...</p>
  if (error)   return <ApiRequestError error={error} />

  return (
    <WidgetTable title="Companies" columns={COLUMNS}>
      {sortCompanies(data.b2bReport.companies).map(company => (
        <WidgetTable.TableRow key={company.id}>
          <td className="px-6 py-4 whitespace-nowrap">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <img className="w-8 rounded-full object-cover" src={company.logoUrl} />
              </div>
              <div className="ml-4">
                <span className="text-sm font-medium text-gray-900">{company.name}</span>
              </div>
            </div>
          </td>

          <WidgetTable.TableCellText text={`${company.country.emoji} ${company.country.name}`} />
          <WidgetTable.TableCellText text={company.patientsCount}                              />
          <WidgetTable.TableCellText text={company.engagedPatientsInLast30DaysCount}           />
          <WidgetTable.TableCellText text={company.patientsOnMobileAppInLast30DaysCount}       />
          <WidgetTable.TableCellText text={company.patientsWithoutProfessionalCount}           />
          <WidgetTable.TableCellText text={formatDateMonthAndYear(company.createdAt)}          />

          <td className="px-6 py-4 whitespace-nowrap">
            <span className={classNames(
              "px-2 inline-flex text-sm leading-5 font-semibold rounded-full",
              company.status == ACTIVE_STATUS ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"
            )}>
              {company.status}
            </span>
          </td>
        </WidgetTable.TableRow>
      ))}
    </WidgetTable>
  )
}

export { Companies }
