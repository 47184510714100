import {
  useQuery,
  gql
} from "@apollo/client"
import sortBy from "lodash/sortBy"
import debounce from "lodash/debounce"
import { TagIcon, CalendarIcon, ChartBarIcon, FireIcon } from '@heroicons/react/outline'
import { useEffect, useState } from "react"
import { BriefcaseIcon, HeartIcon, UsersIcon } from "@heroicons/react/solid"
import Icon                    from '@mdi/react'

import { mdiFoodSteak, mdiRice, mdiCubeOutline, mdiLeaf, mdiDumbbell, mdiSortAscending, mdiSortDescending, mdiImageSearch, mdiImageOutline, mdiImageOffOutline, mdiShakerOutline } from '@mdi/js'
import { Select }          from "../components/Select"
import { Input }           from "../components/Input"
import { Banner }          from "../components/Banner"
import { CardWithMetric }  from "../components/CardWithMetric"
import { Button }          from "../components/Button"
import { WidgetTable }     from "../components/WidgetTable"
import { ApiRequestError } from "../components/ApiRequestError"
import { formatDateMonthAndYear, formatNumericValue, generateRequestContextHeaders, Utils } from "../Utils"

const QUERY = gql`
  query {
    recipesReport {
      prescriptionMetricsUpdatedAt

      systemRecipes {
        count
        recipesPerLanguage {
          language {
            emoji
          }
          count
        }
      }

      communityRecipes {
        count
        recipesPerLanguage {
          language {
            emoji
          }
          count
        }
      }
    }
  }
`

const RECIPES_DATA_QUERY = gql`
  query($page: Int!, $recipeGroupId: Int, $languageId: Int, $name: String, $imageFilter: Int!, $sortField: String!, $sortDirection: String!) {
    recipesReport {
      systemAndCommunityRecipes {
        recipesPaginated(page: $page, recipeGroupId: $recipeGroupId, languageId: $languageId, name: $name, imageFilter: $imageFilter, sortField: $sortField, sortDirection: $sortDirection) {
          pagination {
            currentPage
            lastPage
            totalPages
            totalRecords
            recordsPerPage
          }

          records {
            id
            hasImage
            imageUrl
            createdAt
            likesCount
            recipeGroupId
            recipeCategories {
              id
              name
            }

            food {
              name
              language {
                id
                emoji
              }
              prescriptionsCount
              professionalsCount

              energyKcalPer100Grams
              proteinGramsPer100Grams
              fatGramsPer100Grams
              carbohydrateGramsPer100Grams
              sugarsGramsPer100Grams
              fiberGramsPer100Grams
              sodiumGramsPer100Grams
            }
          }
        }
      }
    }
  }
`

const recipeGroups = {
  systemAndCommunity: 0,
  system:             1,
  community:          2
}

const sortOptions = {
  name:          "name_for_sorting",
  professionals: "professionals_count",
  prescriptions: "prescriptions_count",
  likes:         "likes_count",
  createdAt:     "created_at",
  energy:        "energy_kcal_value",
  protein:       "protein_value",
  fat:           "fat_value",
  carbohydrate:  "carbohydrate_value",
  sugar:         "suga_valuer",
  fiber:         "fiber_value",
  sodium:        "sodium_value"
}

const sortOrderOptions = {
  ascending:  "asc",
  descending: "desc"
}

const languageFilterOptions = {
  all:                  -1,
  portuguese:           0,
  english:              1,
  brazilian_portuguese: 2,
  spanish:              3,
  french:               4,
  italian:              5,
  german:               6
}

const imageFilterOptions = {
  none:         0,
  withImage:    1,
  withoutImage: 2,
}

const recipeGroupselectOptions = [
  { value: recipeGroups.systemAndCommunity, name: "All recipes"  },
  { value: recipeGroups.system            , name: "⚙️ System"     },
  { value: recipeGroups.community         , name: "🎓 Community" },
]

const languageSelectOptions = [
  { value: languageFilterOptions.all,                  name: "All languages"  },
  { value: languageFilterOptions.spanish,              name: "🇪🇸 Spanish"     },
  { value: languageFilterOptions.portuguese,           name: "🇵🇹 Portuguese"  },
  { value: languageFilterOptions.brazilian_portuguese, name: "🇧🇷 Brazilian"   },
  { value: languageFilterOptions.italian,              name: "🇮🇹 Italian"     },
  { value: languageFilterOptions.french,               name: "🇫🇷 French"      },
  { value: languageFilterOptions.english,              name: "🇬🇧 English"     },
  { value: languageFilterOptions.german,               name: "🇩🇪 German"      },
]

const sortSelectOptions = [
  { value: sortOptions.name         , name: "Sort by name"          },
  { value: sortOptions.professionals, name: "Sort by professionals" },
  { value: sortOptions.prescriptions, name: "Sort by prescriptions" },
  { value: sortOptions.likes        , name: "Sort by likes"         },
  { value: sortOptions.createdAt    , name: "Sort by creation date" },
  { value: sortOptions.energy       , name: "Sort by energy"        },
  { value: sortOptions.protein      , name: "Sort by protein"       },
  { value: sortOptions.fat          , name: "Sort by fat"           },
  { value: sortOptions.carbohydrate , name: "Sort by carbohydrate"  },
  { value: sortOptions.sugar        , name: "Sort by sugar"         },
  { value: sortOptions.fiber        , name: "Sort by fiber"         },
  { value: sortOptions.sodium       , name: "Sort by sodium"        },
]

function generateRecipeUrl(recipe) {
  return `https://app.nutrium.com/recipes/${recipe.id}/creation`
}

function WidgetTableHeader({filterOptions, setFilterOptions}) {
  const { imageFilter, sortOrder } = filterOptions

  return (
    <div className="px-4 py-5 sm:px-6 flex items-center justify-center">
      <h3 className="text-lg leading-6 font-medium text-gray-900 min-w-[100px]">Recipes</h3>
      <div className="flex items-center gap-4 grow">
        <div className="grow">
          <Input placeholder="Search by name" onChange={debounce((value) => setFilterOptions({...filterOptions, page: 1, nameFilter: value}), 500)} initialValue={filterOptions.nameFilter} />
        </div>

        <div>
          <Select
            onChange={event => setFilterOptions({...filterOptions, page: 1, recipeGroupId: +event.target.value})}
            value   ={filterOptions.recipeGroupId}
            options ={recipeGroupselectOptions}
          />
        </div>

        <div>
          <Select
            onChange={event => setFilterOptions({...filterOptions, page: 1, languageFilterId: +event.target.value})}
            value   ={filterOptions.languageFilterId}
            options ={languageSelectOptions}
          />
        </div>

        <div>
          <Select
            onChange={event => setFilterOptions({...filterOptions, page: 1, sortByCriteria: event.target.value})}
            value   ={filterOptions.sortByCriteria}
            options ={sortSelectOptions}
          />
        </div>

        <Button onClick={() => setFilterOptions({...filterOptions, page: 1, sortOrder: sortOrder == sortOrderOptions.ascending ? sortOrderOptions.descending : sortOrderOptions.ascending})}>
          {sortOrder == sortOrderOptions.ascending
            ?
              <Icon path={mdiSortAscending} size="16px" />
            :
              <Icon path={mdiSortDescending} size="16px" />
          }
        </Button>

        <Button onClick={() => setFilterOptions({...filterOptions, page: 1, imageFilter: (imageFilter + 1) % 3})}>
          { imageFilter === imageFilterOptions.none         && <Icon path={mdiImageSearch}     size="16px" /> }
          { imageFilter === imageFilterOptions.withImage    && <Icon path={mdiImageOutline}    size="16px" /> }
          { imageFilter === imageFilterOptions.withoutImage && <Icon path={mdiImageOffOutline} size="16px" /> }
        </Button>
      </div>
    </div>
  )
}

function NutritionalInformationsTableRow({food}) {
  const data = {
    energy:       [ [ "hero", FireIcon          ], [ "text-yellow-800", "bg-yellow-100"   ], food.energyKcalPer100Grams        ],
    protein:      [ [ "mdi", mdiDumbbell        ], [ "text-blue-800", "bg-blue-100"       ], food.proteinGramsPer100Grams      ],
    fat:          [ [ "mdi", mdiFoodSteak       ], [ "text-red-800", "bg-red-100"         ], food.fatGramsPer100Grams          ],
    carbohydrate: [ [ "mdi", mdiRice            ], [ "text-purple-800", "bg-purple-100"   ], food.carbohydrateGramsPer100Grams ],
    sugar:        [ [ "mdi", mdiCubeOutline     ], [ "text-orange-800", "bg-orange-100"   ], food.sugarsGramsPer100Grams       ],
    fiber:        [ [ "mdi", mdiLeaf            ], [ "text-emerald-800", "bg-emerald-100" ], food.fiberGramsPer100Grams        ],
    sodium:       [ [ "mdi", mdiShakerOutline   ], [ "text-amber-800", "bg-amber-100"     ], food.sodiumGramsPer100Grams       ]
  }

  return (
    <>
      <td className="px-6 py-4 whitespace-nowrap" style={{maxWidth: "1500px"}}>
        {Object.keys(data).map(nutrient =>
            <span key={nutrient} className={`inline-flex items-center justify-center rounded-full ${data[nutrient][1][1]} px-2.5 py-0.5 text-xs font-medium ${data[nutrient][1][0]} mr-4 min-w-[80px]`}>
              {data[nutrient][0][0] == "hero" && nutrient == "energy" &&
                <FireIcon className="h-4 w-4" />
              }
              {data[nutrient][0][0] == "mdi" &&
                <Icon path={data[nutrient][0][1]} size="16px" />
              }
              {data[nutrient][0][0] == "fa" &&
                <i class={`fa-solid fa-${data[nutrient][0][1]}`} />
              }

              <span className="ml-2">{Utils.formatNutritionValue(data[nutrient][2], nutrient == "energy" ? "kcal" : "g")}</span>
            </span>
        )}
      </td>
    </>
  )
}

function RecipesTableRows({userData, setPaginatorProps, filterOptions, setFilterOptions}) {
  const requestContext     = generateRequestContextHeaders(userData)
  requestContext.variables = { page: filterOptions.page, imageFilter: filterOptions.imageFilter, sortField: filterOptions.sortByCriteria, sortDirection: filterOptions.sortOrder }

  if (filterOptions.recipeGroupId)         { requestContext.variables.recipeGroupId = filterOptions.recipeGroupId    }
  if (filterOptions.languageFilterId >= 0) { requestContext.variables.languageId    = filterOptions.languageFilterId }
  if (filterOptions.nameFilter)            { requestContext.variables.name          = filterOptions.nameFilter       }

  const { loading, error, data } = useQuery(RECIPES_DATA_QUERY, requestContext)

  const pagination = data?.recipesReport?.systemAndCommunityRecipes?.recipesPaginated?.pagination
  let customPagination = null
  if (pagination) {
    customPagination = {...pagination}
    customPagination.onClick = (page) => setFilterOptions({...filterOptions, page: page})
  }

  useEffect(
    () => setPaginatorProps(customPagination),
    [pagination]
  )

  if (loading) return <WidgetTable.TableRow><WidgetTable.TableCellText text="Loading..." /></WidgetTable.TableRow>
  if (error) return <ApiRequestError error={error} />

  const recipes = data.recipesReport.systemAndCommunityRecipes.recipesPaginated.records

  return (
    <>
      {recipes.map(recipe => (
        <WidgetTable.TableRow key={recipe.id}>
          <td className="px-6 py-4 break-words max-w-md min-w-[400px]">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <img className="h-12 w-12 object-cover rounded-full" src={recipe.imageUrl} />
              </div>
              <div className="ml-6 flex flex-col gap-2">
                <a href={generateRecipeUrl(recipe)} className="text-sm font-medium text-gray-900 hover:underline" target="_blank">
                  {recipe.food.name}
                </a>
                <div className="flex flex-col gap-1">
                  {recipe.recipeCategories.map(recipeCategory =>
                    <div key={recipeCategory.id} className="flex items-center text-xs">
                      <TagIcon className="h-4 w-4 mr-2 text-amber-500"/>
                      <span>{recipeCategory.name}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </td>

          <WidgetTable.TableCellText text={recipe.food.language.emoji} />
          <WidgetTable.TableCellText text={recipe.recipeGroupId == recipeGroups.system ? "⚙️" : "🎓" } />

          <td className="px-6 py-4 whitespace-nowrap" style={{maxWidth: "1500px"}}>
            <span className="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
              <UsersIcon className="h-4 w-4 mr-2"/>
              <span>{formatNumericValue(recipe.food.professionalsCount)}</span>
            </span>
          </td>

          <td className="px-6 py-4 whitespace-nowrap" style={{maxWidth: "1500px"}}>
            <span className="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
              <ChartBarIcon className="h-4 w-4 mr-2"/>
              <span>{formatNumericValue(recipe.food.prescriptionsCount)}</span>
            </span>
          </td>

          <td className="px-6 py-4 whitespace-nowrap" style={{maxWidth: "1500px"}}>
            <span className="inline-flex items-center rounded-full bg-pink-100 px-2.5 py-0.5 text-xs font-medium text-pink-800">
              <HeartIcon className="h-4 w-4 mr-2"/>
              <span>{formatNumericValue(recipe.likesCount)}</span>
            </span>
          </td>

          <NutritionalInformationsTableRow food={recipe.food} />

          <td className="px-6 py-4 whitespace-nowrap" style={{maxWidth: "1500px"}}>
            <span className="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800">
              <CalendarIcon className="h-4 w-4 mr-2"/>
              <span>{formatDateMonthAndYear(recipe.createdAt)}</span>
            </span>
          </td>
        </WidgetTable.TableRow>
      ))}
    </>
  )
}

function Recipes({userData}) {
  const [paginatorProps, setPaginatorProps] = useState(null)
  const [filterOptions, setFilterOptions]   = useState({
    recipeGroupId:    recipeGroups.systemAndCommunity,
    languageFilterId: languageFilterOptions.all,
    nameFilter:       "",
    imageFilter:      imageFilterOptions.none,
    sortByCriteria:   sortOptions.name,
    sortOrder:        sortOrderOptions.ascending,
    page:             1
  })

  const { loading, error, data } = useQuery(QUERY, generateRequestContextHeaders(userData))

  if (loading) return <p>Loading...</p>
  if (error)   return <ApiRequestError error={error} />

  const widgetTableHeader = (
    <WidgetTableHeader
      filterOptions   ={filterOptions}
      setFilterOptions={setFilterOptions}
    />
  )

  return (
    <>
      <Banner type="info" text={`The metrics for recipe prescriptions were last updated at ${formatDateMonthAndYear(data.recipesReport.prescriptionMetricsUpdatedAt)}`} />

      <div className="mt-5 grid grid-cols-[1fr_3fr] gap-5">
        <CardWithMetric name="System recipes" value={formatNumericValue(data.recipesReport.systemRecipes.count)} />
        <CardWithMetric name="System recipes per language" values={sortBy(data.recipesReport.systemRecipes.recipesPerLanguage, e => e.count * -1)} valueFontSizeClass={"text-xl"} formatValues={true} />
      </div>

      <div className="mt-5 grid grid-cols-[1fr_3fr] gap-5">
        <CardWithMetric name="Community recipes" value={formatNumericValue(data.recipesReport.communityRecipes.count)} />
        <CardWithMetric name="Community recipes per language" values={sortBy(data.recipesReport.communityRecipes.recipesPerLanguage, e => e.count * -1)} valueFontSizeClass={"text-xl"} formatValues={true} />
      </div>

      <div className="mt-8">
        <WidgetTable titleComponent={widgetTableHeader} paginatorProps={paginatorProps}>
          <RecipesTableRows
            userData         ={userData}
            filterOptions    ={filterOptions}
            setFilterOptions ={setFilterOptions}
            setPaginatorProps={setPaginatorProps}
          />
        </WidgetTable>
      </div>
    </>
  )
}

export { Recipes }
