import { gql } from "@apollo/client"
import moment  from "moment"
import sortBy  from "lodash/sortBy"

const AccountRole = {
  ROOT:                 0,
  ADMIN:                1,
  B2B_DEPARTMENT:       2,
  SAAS_DEPARTMENT:      3,
  MARKETING_DEPARTMENT: 4,
  NUTRITION_DEPARTMENT: 5,
  PRODUCT_DEPARTMENT:   6
}

const INFINITE_VARIATION_VALUE = 49000049

const NUTRITION_SERVICE_REQUEST_STATUS_DATA = {
  0: [ "Pending"    , "bg-gray-100 text-gray-800"     ],
  1: [ "Accepted"   , "bg-green-100 text-green-800"   ],
  2: [ "Rejected"   , "bg-red-100 text-red-800"       ],
  3: [ "Canceled"   , "bg-orange-50 text-orange-700"  ],
  4: [ "Unconfirmed", "bg-yellow-100 text-yellow-800" ],
  5: [ "Expired"    , "bg-gray-100 text-gray-800"     ],
}

const SCHEDULING_EVENTS_QUERY = gql`
  query FetchSchedulingEventsQuery($beginDate: ISO8601DateTime, $endDate: ISO8601DateTime, $companyId: Int, $scheduledBy: String) {
    b2bReport {
      companies {
        id
        name
      }

      schedulingEvents(beginDate: $beginDate, endDate: $endDate, companyId: $companyId, scheduledBy: $scheduledBy) {
        id
        date
        statusId
        observations
        reply
        createdAt
        appointmentNumber
        type
        hasRequest
        appointmentSchedulingStatusChangedById
        appointmentCompleted
        nutritionServiceRequestStatusChangeReasonId
        professional {
          id
          name
          avatarUrl
        }

        user {
          name
          phoneNumber
          account {
            email
          }
          company {
            id
            code
            name
            logoUrl
          }
          origin
        }
      }
    }
  }
`

const PATIENT_ENGAGEMENTS_QUERY = gql`
  query FetchPatientEngagementsQuery($companyId: Int!) {
    b2bReport {
      companies {
        id
        name
      }

      patientEngagements(companyId: $companyId) {
        id
        gender
        birthdate
        daysInMobileApp
        firstDayInMobileApp
        messagesSent
        emailsSent
        individualMessagesReceived
        totalScheduledAppointments
        totalCompletedAppointments
        firstAppointmentDate
        nextAppointmentDate
      }
    }
  }
`

const RECIPES_PRESCRIPTIONS_CSV_REPORT_QUERY = gql`
  query {
    recipesReport {
      prescriptionMetricsCsvFile {
        fileContent
        fileName
      }
    }
  }
`

function getFirstAndLastName(name) {
  const names      = name.split(" ")
  const first_name = names[0]
  const last_name  = names.length > 1 ? names[names.length - 1] : null

  if (last_name) {
    return `${first_name} ${last_name}`
  } else {
    return first_name
  }
}

function generateCompanyUrl(company) {
  return `/companies/${company.id}/${company.code}`
}

function generateProfessionalUrl(professional) {
  return `https://app.nutrium.com/admin/accounts?query=${professional.id}`
}

function formatDate(date) {
  return moment(date).format("MMM D, HH:mm")
}

function formatDateTime(date) {
  return moment(date).format("YYYY MMM D, HH:mm")
}

function formatDateMonthAndYear(date) {
  return moment(date).format("MMM D, YYYY")
}

function getNutritionServiceRequestStatusName(nutritionServiceRequestStatusId) {
  return NUTRITION_SERVICE_REQUEST_STATUS_DATA[nutritionServiceRequestStatusId][0]
}

function getNutritionServiceRequestStatusColor(nutritionServiceRequestStatusId) {
  return NUTRITION_SERVICE_REQUEST_STATUS_DATA[nutritionServiceRequestStatusId][1]
}

function calculatePercentage(numerator, denominator) {
  return Math.round((numerator / denominator) * 100)
}

function formatToPercentage(value) {
  return Math.round(value * 100)
}

function formatToPercentageWithDecimal(value) {
  return (value * 100).toFixed(1)
}

function generateRequestContextHeaders(userData) {
  return {
    context: {
      headers: {
        'Authorization': `Bearer ${userData.authenticationToken}`
      }
    }
  }
}

const permissionsAccountRoles = {
  nutritionServiceRequestsListing:      [ AccountRole.ROOT, AccountRole.ADMIN, AccountRole.NUTRITION_DEPARTMENT, AccountRole.PRODUCT_DEPARTMENT ],
  nutritionServiceRequestsObservations: [ AccountRole.ROOT, AccountRole.ADMIN                                   ],
  exportPage:                           [ AccountRole.ROOT, AccountRole.ADMIN, AccountRole.B2B_DEPARTMENT       ],
  professionalsCustomersListing:        [ AccountRole.ROOT, AccountRole.ADMIN, AccountRole.SAAS_DEPARTMENT      ],
}

const Utils = {
  inDevelopmentEnvironment: () => window.location.host != "admin.nutrium.com",

  permissions: {
    canSeeNutritionServiceRequestsListing:      (accountRoleId) => permissionsAccountRoles.nutritionServiceRequestsListing.includes(accountRoleId)     ,
    canSeeNutritionServiceRequestsObservations: (accountRoleId) => permissionsAccountRoles.nutritionServiceRequestsObservations.includes(accountRoleId),
    canSeeExportPage:                           (accountRoleId) => permissionsAccountRoles.exportPage.includes(accountRoleId)                          ,
    canSeeProfessionalsCustomersListing:        (accountRoleId) => permissionsAccountRoles.professionalsCustomersListing.includes(accountRoleId)       ,
  },

  formatNutritionValue: (value, unit) => typeof(value) == "number" ? `${value} ${unit}` : "-"
}

const PagePaths = {
  home: "/",
  b2b: {
    home:          "/b2b",
    companies:     "/b2b/companies",
    requests:      "/b2b/requests",
    professionals: "/b2b/professionals",
    patients:      "/b2b/patients",
    challenges:    "/b2b/challenges"
  },
  saas: {
    home: "/saas",
  },
  brands: {
    home: "/brands",
  },
  recipes: {
    home: "/recipes",
  },
  misc: {
    export: "/export",
  },
  translations: "/translations"
}

function generateCountriesText(countries) {
  return sortBy(countries, country => country.name.toLocaleUpperCase())
    .map((country) => country.emoji)
    .join(" ")
}

function downloadCSVFile(csvFile, { filename, fileContent } = {}) {
  const derivedFilename    = csvFile?.fileName    || filename
  const derivedFileContent = csvFile?.fileContent || fileContent

  const blob = new Blob([derivedFileContent])
  const a    = window.document.createElement("a")

  a.href     = window.URL.createObjectURL(blob, { type: "text/plain" })
  a.download = derivedFilename

  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

function formatNumericValue(value, roundValue) {
  // INFO - The value parameter may sometimes be null / undefined
  if (typeof(value) == "number") {
    const adjustedValue = roundValue ? Math.round(value) : value

    return adjustedValue.toLocaleString("en-us")
  } else {
    return "-"
  }
}

function formatValueInEuros(value, roundValue) {
  const adjustedValue = roundValue ? Math.round(value) : value

  return `€ ${adjustedValue.toLocaleString("en-us")}`
}

export { getFirstAndLastName, generateCompanyUrl, formatDate, formatDateTime, formatDateMonthAndYear, getNutritionServiceRequestStatusName, getNutritionServiceRequestStatusColor, generateProfessionalUrl, calculatePercentage, SCHEDULING_EVENTS_QUERY, PATIENT_ENGAGEMENTS_QUERY, formatToPercentage, generateRequestContextHeaders, INFINITE_VARIATION_VALUE, Utils, PagePaths, generateCountriesText, formatToPercentageWithDecimal, AccountRole, downloadCSVFile, formatValueInEuros, RECIPES_PRESCRIPTIONS_CSV_REPORT_QUERY, formatNumericValue }
