import { gql } from "@apollo/client"

import { DownloadData } from './DownloadData'

const QUERY = gql`
  query($brandIds: [ Int! ]) {
    brandsReport {
      brandsPrescriptionCsvFile(brandIds: $brandIds) {
        csvFile {
          fileContent
          fileName
        }
      }
    }
  }
`

function BrandsFoodPrescriptionDataExporter({userData, brandIds, onAfterDownload}) {
  return <DownloadData
    userData       ={userData}
    variables      ={{brandIds}}
    onAfterDownload={onAfterDownload}
    selectData     ={data => data.brandsReport.brandsPrescriptionCsvFile.csvFile}
    query          ={QUERY}
  />
}

export { BrandsFoodPrescriptionDataExporter }
